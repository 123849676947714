import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Icon from "@material-ui/core/Icon";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import { Link } from "gatsby";
import featuresStyle from "ps-assets/jss/featuresStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import PsInfoArea from "ps-components/PsInfoArea/PsInfoArea";
import Carousel from "react-slick";
import CustomMap from "ps-components/Maps/CustomMap.jsx";
import MediaQuery from "react-responsive";
import ReactSVG from "react-svg";
import Hidden from "@material-ui/core/Hidden";
import PropTypes from "prop-types";
var apiBaseUrl = process.env.API_URL;

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  initialSlide: 0,
  arrows: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: false,
        dots: true
      }
    },
    {
      breakpoint: 750,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        infinite: false,
        dots: true
      }
    }
  ]
};

class LandingSection extends React.Component {
  state = { activeCard: 0 };

  getTrendingCardContent = function(item) {
    const { classes } = this.props;
    if (item.url != ``) {
      if (item.url == `customer-banner`) {
        if (item.icon) {
          return (
            <div style={{ marginTop: `25px` }}>
              {item.productLink.playstore && (
                <div className={classes.playstoreIcon}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={item.productLink.playstore.link}
                  >
                    <img
                      src={item.productLink.playstore.imageUrl}
                      alt={item.productLink.playstore.alt}
                    />
                  </a>
                </div>
              )}
              {item.productLink.appStore && (
                <div className={classes.appStoreIcon}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={item.productLink.appStore.link}
                  >
                    <img
                      src={item.productLink.appStore.imageUrl}
                      alt={item.productLink.appStore.link}
                    />
                  </a>
                </div>
              )}
              <img
                className={classes.customerBanner}
                src={item.icon.publicURL}
                alt={item.title}
              />
            </div>
          );
        }
      } else {
        return (
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={item.url}
            style={{ marginLeft: 20, marginRight: 20 }}
          >
            {item.icon && (
              <img
                src={item.icon.publicURL}
                alt="..."
                style={{
                  margin: `0 auto`,
                  width: `100%`,
                  borderRadius: `6px`
                }}
              />
            )}
          </a>
        );
      }
    } else {
      if (item.icon) {
        return (
          <img
            src={item.icon.publicURL}
            alt="..."
            style={{
              margin: `0 auto`,
              width: `100%`,
              borderRadius: `6px`,
              marginTop: 23,
              marginRight: 20
            }}
          />
        );
      }
    }
  };

  render() {
    const {
      classes,
      title,
      description,
      type,
      sectionImage,
      background,
      cards,
      cardsBackground,
      additionalDetails
    } = this.props;
    return (
      <div className={classes.features3}>
        <GridContainer justify="center" className={classes.section}>
          <GridItem xs={12} sm={8} md={8}>
            <h2 className={classes.title}>{title}</h2>
            {description && (
              <p className={classes.description} style={{fontSize:"18px"}}>{description}</p>
            )}
          </GridItem>
        </GridContainer>
        {type === `onlyCardsWithFeature` && (
          <GridContainer justify="center">
            {cards.map((item, i) => {
              let itemElement;
              if (item.url && item.url.includes(`https://`)) {
                itemElement = (
                  <a
                    rel="noopener noreferrer"
                    href={item.url}
                    target="_blank"
                    key={`feature-${i}`}
                  >
                    <Card
                      background={background}
                      style={{
                        width:'130px',
                        height: `130px`,
                        marginLeft: `6px`,
                        marginRight: `6px`
                      }}
                      className={`${classes.customCard} ${classes.featureEffect}`}
                    >
                      <CardBody
                        background={background}
                        className={classes.textCenter}
                        style={{
                          paddingTop: `20px`,
                          paddingBottom: `10px`,
                          paddingLeft: 10,
                          paddingRight: 10
                        }}
                      >
                        <div style={{ textAlign: `center` }}>
                          <ReactSVG
                            src={item.icon.publicURL}
                            style={{ width: 40, height: 40, margin: `0 auto` }}
                          />
                        </div>
                        <h6
                          className={`${
                            background ? classes.cardTitleWhite : classes.title
                          }`}
                          style={{
                            paddingTop: `6px`,
                            paddingBottom: `10px`,
                            justifyContent: `center`,
                            fontSize: `13px`,
                            textTransform: `capitalize`
                          }}
                        >
                          {item.title}
                        </h6>
                      </CardBody>
                    </Card>
                  </a>
                );
              } else if (item.sub_category === `call_back`) {
                itemElement = (
                  <Button
                    simple
                    style={{ margin: `0`, padding: `0` }}
                    className={`clickme ${classes.callBackButton}`}
                    key={`feature-${i}`}
                  >
                    <Card
                      background={background}
                      style={{
                        width:'130px',
                        height: `130px`,
                        marginLeft: `6px`,
                        marginRight: `6px`
                      }}
                      className={`${classes.customCard} ${classes.featureEffect}`}
                    >
                      <CardBody
                        background={background}
                        className={classes.textCenter}
                        style={{
                          paddingTop: `20px`,
                          paddingBottom: `10px`,
                          paddingLeft: 10,
                          paddingRight: 10
                        }}
                      >
                        <div style={{ textAlign: `center` }}>
                          <ReactSVG
                            src={item.icon.publicURL}
                            style={{ width: 40, height: 40, margin: `0 auto` }}
                          />
                        </div>
                        <h6
                          className={`${
                            background ? classes.cardTitleWhite : classes.title
                          }`}
                          style={{
                            paddingTop: `6px`,
                            paddingBottom: `10px`,
                            justifyContent: `center`,
                            fontSize: `13px`,
                            textTransform: `capitalize`,
                            whiteSpace: `pre-wrap`
                          }}
                        >
                          {item.title}
                        </h6>
                      </CardBody>
                    </Card>
                  </Button>
                );
              } else {
                itemElement = (
                  <Link to={item.url || `/`} key={`feature-${i}`}>
                    <Card
                      background={background}
                      style={{
                        width:'130px',
                        height: `130px`,
                        marginLeft: `6px`,
                        marginRight: `6px`
                      }}
                      className={`${classes.customCard} ${classes.featureEffect}`}
                    >
                      <CardBody
                        background={background}
                        className={classes.textCenter}
                        style={{
                          paddingTop: `20px`,
                          paddingBottom: `10px`,
                          paddingLeft: 8,
                          paddingRight: 8
                        }}
                      >
                        <div style={{ textAlign: `center` }}>
                          <ReactSVG
                            src={item.icon.publicURL}
                            style={{ width: 40, height: 40, margin: `0 auto` }}
                          />
                        </div>
                        <p
                          className={`${
                            background ? classes.cardTitleWhite : classes.title
                          }`}
                          style={{
                            paddingTop: `6px`,
                            paddingBottom: `10px`,
                            justifyContent: `center`,
                            fontSize: `13px`,
                            textTransform: `capitalize`
                          }}
                        >
                          {item.title}
                        </p>
                      </CardBody>
                    </Card>
                  </Link>
                );
              }
              return itemElement;
            })}
          </GridContainer>
        )}
        {type === `onlyCards` && (
          <div>
            <GridContainer>
              {cards.map(item => {
                return (
                  <GridItem xs={12} sm={4} md={2} lg={2} key={item.id}>
                    <a rel="noopener" href={item.url || `#`}>
                      <Card
                        background={background}
                        style={{
                          minHeight: `180px`,
                          width: `180px`,
                          backgroundImage: `url(${
                            background ? item.image.src : ``
                          })`,
                          ...(additionalDetails ? additionalDetails.style : {})
                        }}
                      >
                        <CardBody
                          background={background}
                          className={classes.textCenter}
                          style={{
                            paddingTop: `20px`,
                            paddingBottom: `10px`,
                            paddingLeft: 10,
                            paddingRight: 10
                          }}
                        >
                          <div>
                            <Icon
                              style={{
                                fontSize: 60
                              }}
                            >
                              {item.icon || `star`}
                            </Icon>
                          </div>
                          <div
                            className={`${
                              background
                                ? classes.cardTitleWhite
                                : classes.title
                            }`}
                            style={{
                              paddingTop: `10px`,
                              paddingBottom: `10px`,
                              fontWeight: 100,
                              fontSize: 20,
                              justifyContent: `center`
                            }}
                          >
                            {item.title}
                          </div>
                        </CardBody>
                      </Card>
                    </a>
                  </GridItem>
                );
              })}
            </GridContainer>
          </div>
        )}
        {type === `cardsWithStats` && (
          <div className={classes.container}>
            <GridContainer
              item
              lg={12}
              style={{ marginLeft: `auto`, marginRight: `auto` }}
            >
              {cards.map(item => {
                return (
                  <GridItem
                    xs={12}
                    sm={12}
                    md={item.gridSize || 12}
                    key={item.id}
                  >
                    <Card
                      background={item.backgroundImage}
                      className={`${classes[item.color]} ${classes.statsCard}`}
                      style={{
                        boxShadow: `none`,
                        // border: "solid 1px #eee",
                        backgroundImage: item.backgroundImage
                          ? `url(${item.backgroundImage})`
                          : `none`,
                        marginLeft: `-10`,
                        marginTop: 0,
                        alignItems: `center`,
                        height: `180px`
                      }}
                    >
                      <CardBody
                        background
                        style={{
                          padding: 0,
                          margin: 0,
                          maxWidth: `100%`,
                          display: `flex`,
                          flexDirection: `row`,
                          flexWrap: `no-wrap`,
                          justifyContent: `space-around`,
                          width: `100%`,
                          ...item.style
                        }}
                      >
                        {item.image && item.image.position === `left` && (
                          <GridContainer style={{ width: `100%` }}>
                            <Hidden smDown>
                              <GridItem
                                md={item.image.gridSize}
                                sm={item.image.gridSize}
                                lg={item.image.gridSize}
                                style={{ paddingRight: `0`, paddingLeft: `0` }}
                              >
                                <img
                                  src={item.image && item.image.src}
                                  style={{
                                    height: `180px`,
                                    borderTopLeftRadius: `3%`,
                                    borderBottomLeftRadius: `3%`
                                  }}
                                />
                              </GridItem>
                            </Hidden>
                            <GridItem
                              md={12 - item.image.gridSize}
                              sm={12}
                              xs={12}
                              lg={12 - item.image.gridSize}
                              style={{
                                paddingLeft: `0`,
                                alignSelf: `center`,
                                textAlign: `center`,
                                paddingBottom: `30px`
                              }}
                            >
                              <div
                                style={{
                                  textAlign: `center`
                                }}
                              >
                                {!item.icon &&
                                  (!item.titleSize ||
                                    item.titleSize === `lg`) && (
                                  <p
                                    className={`${classes.customCardTitleWhite}`}
                                  >
                                    {item.title}
                                  </p>
                                )}
                                {!item.icon && item.titleSize === `sm` && (
                                  <h2
                                    className={`${classes.customCardTitleWhite}`}
                                    style={{ lineHeight: 1.33 }}
                                  >
                                    {item.title}
                                  </h2>
                                )}
                                {item.icon && (
                                  <h5
                                    className={`${classes.customCardTitleWhite}`}
                                    style={{ lineHeight: 1.33 }}
                                  >
                                    {item.title}
                                  </h5>
                                )}
                              </div>
                              {item.description && (
                                <p
                                  style={{
                                    color: `#ffffff`,
                                    fontWeight: `normal`,
                                    fontStyle: `normal`,
                                    fontStretch: `normal`,
                                    lineHeight: 1.33,
                                    letterSpacing: `normal`,
                                    textAlign: `center`,
                                    fontSize: `15px`
                                  }}
                                >
                                  {item.description}
                                </p>
                              )}
                            </GridItem>
                          </GridContainer>
                        )}
                        {item.image && item.image.position === `right` && (
                          <GridContainer style={{ width: `100%` }}>
                            <GridItem
                              md={12 - item.image.gridSize}
                              sm={12}
                              xs={12}
                              lg={12 - item.image.gridSize}
                              style={{
                                paddingLeft: `0`,
                                alignSelf: `center`,
                                textAlign: `center`,
                                paddingBottom: `30px`
                              }}
                            >
                              <div
                                style={{
                                  textAlign: `center`
                                }}
                              >
                                {!item.icon &&
                                  (!item.titleSize ||
                                    item.titleSize === `lg`) && (
                                  <p
                                    className={`${classes.customCardTitleWhite}`}
                                  >
                                    {item.title}
                                  </p>
                                )}
                                {!item.icon && item.titleSize === `sm` && (
                                  <h2
                                    className={`${classes.customCardTitleWhite}`}
                                    style={{ lineHeight: 1.33 }}
                                  >
                                    {item.title}
                                  </h2>
                                )}
                                {item.icon && (
                                  <h5
                                    className={`${classes.customCardTitleWhite}`}
                                    style={{ lineHeight: 1.33 }}
                                  >
                                    {item.title}
                                  </h5>
                                )}
                              </div>
                              {item.description && (
                                <p
                                  style={{
                                    color: `#ffffff`,
                                    fontWeight: `normal`,
                                    fontStyle: `normal`,
                                    fontStretch: `normal`,
                                    lineHeight: 1.33,
                                    letterSpacing: `normal`,
                                    textAlign: `center`,
                                    fontSize: `15px`
                                  }}
                                >
                                  {item.description}
                                </p>
                              )}
                            </GridItem>
                            <Hidden smDown>
                              <GridItem
                                md={item.image.gridSize}
                                sm={item.image.gridSize}
                                lg={item.image.gridSize}
                                style={{ paddingRight: `0`, paddingLeft: `0` }}
                              >
                                <img
                                  src={item.image && item.image.src}
                                  style={{
                                    height: `180px`,
                                    width: `100%`,
                                    borderTopRightRadius: `3%`,
                                    borderBottomRightRadius: `3%`
                                  }}
                                />
                              </GridItem>
                            </Hidden>
                          </GridContainer>
                        )}
                        {!(
                          item.image &&
                          (item.image.position === `left` ||
                            item.image.position === `right`)
                        ) && (
                          <div
                            style={{
                              alignSelf: `center`,
                              marginBottom: `28px`,
                              textAlign: `center`,
                              flexGrow: `2`,
                              maxWidth:
                                item.gridSize > 3 && item.gridSize <= 6
                                  ? `400px`
                                  : item.gridSize > 6
                                    ? `500px`
                                    : `220px`
                            }}
                          >
                            {item.icon && (
                              <div
                                style={{
                                  paddingTop: 0
                                }}
                              >
                                <img
                                  alt=" "
                                  style={{
                                    height: 70,
                                    color: `#FFFFFF`
                                  }}
                                  src={item.icon}
                                />
                              </div>
                            )}
                            <div
                              style={{
                                textAlign: `center`
                              }}
                            >
                              {!item.icon &&
                                (!item.titleSize ||
                                  item.titleSize === `lg`) && (
                                <p
                                  className={`${classes.customCardTitleWhite}`}
                                >
                                  {item.title}
                                </p>
                              )}
                              {!item.icon && item.titleSize === `sm` && (
                                <p
                                  className={`${classes.customCardTitleWhite}`}
                                  style={{
                                    lineHeight: 1,
                                    fontSize: `3.3em`,
                                    marginBottom: `0px`,
                                    paddingBottom: `0px`,
                                    paddingTop: `10px`
                                  }}
                                >
                                  {item.title}
                                </p>
                              )}
                              {item.icon && (
                                <p
                                  className={`${classes.customCardTitleWhite}`}
                                  style={{
                                    lineHeight: 1.33,
                                    fontSize: `15px`,
                                    paddingTop: `0px`,
                                    paddingBottom: `0px`,
                                    marginBottom: `0px`,
                                    paddingLeft: `5px`,
                                    paddingRight: `5px`
                                  }}
                                >
                                  {item.title}
                                </p>
                              )}
                            </div>
                            {item.description && !item.icon && (
                              <p
                                style={{
                                  color: `#ffffff`,
                                  fontWeight: `normal`,
                                  fontStyle: `normal`,
                                  fontStretch: `normal`,
                                  lineHeight: 1.33,
                                  letterSpacing: `normal`,
                                  textAlign: `center`,
                                  fontSize: `15px`,
                                  paddingRight: `10px`,
                                  paddingLeft: `10px`
                                }}
                              >
                                {item.description}
                              </p>
                            )}
                            {item.description && item.icon && (
                              <h6
                                style={{
                                  color: `#ffffff`,
                                  fontSize: `15px`,
                                  marginTop: `0px`,
                                  textTransform: `lowercase`,
                                  paddingBottom: `30px`,
                                  marginBottom: `0px`
                                }}
                              >
                                {item.description}
                              </h6>
                            )}
                          </div>
                        )}
                      </CardBody>
                    </Card>
                  </GridItem>
                );
              })}
            </GridContainer>
          </div>
        )}
        {type === `onlyCardsWithImageOnRight` && (
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Carousel {...settings}>
                  {cards.map(item => {
                    return (
                      <div key={item.id} className={classes.monoChrome}>
                        <GridItem xs={12} sm={12} md={12}>
                          {this.getTrendingCardContent(item)}
                        </GridItem>
                      </div>
                    );
                  })}
                </Carousel>
              </GridItem>
            </GridContainer>
          </div>
        )}
        {type === `imageWithCardsOnLeft` && (
          <div className={classes.container}>
            <MediaQuery maxWidth={767}>
              {() => {
                return (
                  <>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <div>
                          <img
                            src={sectionImage}
                            alt="..."
                            style={{ width: `100%` }}
                          />
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        {cards.map(item => {
                          return (
                            <PsInfoArea
                              key={item.id}
                              className={`${classes.infoArea} ${
                                cardsBackground === `white`
                                  ? classes.infoAreaWithWhiteBackground
                                  : ``
                              }`}
                              icon={item.icon || `credit_card`}
                              title={item.title}
                              description={item.description}
                              iconColor={`primary`}
                            />
                          );
                        })}
                        {additionalDetails.ctaLabel && (
                          <>
                            {additionalDetails.ctaUrl && (
                              <Link to={additionalDetails.ctaUrl}>
                                <Button
                                  round
                                  color={additionalDetails.button.color}
                                  style={{
                                    marginTop: 30,
                                    marginLeft: 20,
                                    width: `160px`
                                  }}
                                >
                                  {additionalDetails.ctaLabel}
                                </Button>
                              </Link>
                            )}
                          </>
                        )}
                        {additionalDetails.ctaLabel1 && (
                          <>
                            {additionalDetails.ctaUrl1 && (
                              <Link to={additionalDetails.ctaUrl1}>
                                <Button
                                  round
                                  color={additionalDetails.button.color}
                                  style={{
                                    marginTop: 30,
                                    marginLeft: 20,
                                    width: `160px`
                                  }}
                                >
                                  {additionalDetails.ctaLabel1}
                                </Button>
                              </Link>
                            )}
                          </>
                        )}
                      </GridItem>
                    </GridContainer>
                  </>
                );
              }}
            </MediaQuery>
          </div>
        )}
        {type === `imageWithImageCardsOnRight` && (
          <div className={classes.container}>
            <MediaQuery maxWidth={767}>
              {() => {
                return (
                  <>
                    <GridContainer>
                      {cards.map((card, index) => {
                        return (
                          <GridItem xs={12} sm={12} md={6} key={index}>
                            <Card
                              className={classes.customCard}
                              plain
                              key={card.id}
                              style={{
                                textAlign: `left`,
                                backgroundColor: `rgba(255,255,255,0.7)`
                              }}
                            >
                              <GridContainer>
                                <GridItem xs={12} sm={4} md={3}>
                                  <CardHeader image plain>
                                    <a
                                      href="#"
                                      onClick={e => e.preventDefault()}
                                      className={classes.awardHeader}
                                    >
                                      <img
                                        src={
                                          card.image
                                            ? card.image
                                            : require(`ps-assets/img/star-award.svg`)
                                        }
                                        alt="..."
                                        style={{
                                          height: 120,
                                          width: 120,
                                          boxShadow: `none`
                                        }}
                                      />
                                    </a>
                                  </CardHeader>
                                </GridItem>
                                <GridItem xs={12} sm={8} md={9}>
                                  <CardBody
                                    style={{
                                      paddingTop: 0
                                    }}
                                  >
                                    <h4 className={classes.responsiveCardTitle}>
                                      {card.title}
                                    </h4>
                                    <p className={classes.description}
                                    dangerouslySetInnerHTML={{__html: [card.description]}}
                                    >
                                    </p>
                                  </CardBody>
                                </GridItem>
                              </GridContainer>
                            </Card>
                          </GridItem>
                        );
                      })}
                      <GridItem
                        xs={12}
                        sm={12}
                        md={12}
                        className={classes.textCenter}
                      >
                        {additionalDetails.ctaLabel && (
                          <>
                            {additionalDetails.ctaUrl && (
                              <Link to={additionalDetails.ctaUrl}>
                                <Button
                                  round
                                  color={additionalDetails.button.color}
                                  style={{
                                    marginTop: 10
                                  }}
                                >
                                  {additionalDetails.ctaLabel}
                                </Button>
                              </Link>
                            )}
                            {!additionalDetails.ctaUrl && (
                              <Button
                                round
                                color={additionalDetails.button.color}
                                style={{
                                  marginTop: 10
                                }}
                              >
                                {additionalDetails.ctaLabel}
                              </Button>
                            )}
                          </>
                        )}
                      </GridItem>
                    </GridContainer>
                  </>
                );
              }}
            </MediaQuery>
          </div>
        )}
        {type === `map` && (
          <GridContainer>
            <GridItem
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ paddingLeft: `0`, paddingRight: `0` }}
            >
              <CustomMap
                id="network"
                searchMessage="Search Network Hospitals"
                category="network"
                icon_url={`https://maps.gstatic.com/mapfiles/place_api/icons/doctor-71.png`}
                icon_size={25}
                url={`${apiBaseUrl}hospital/search`}
                cityUrl={`${apiBaseUrl}hospital/city`}
                defaultView={`list`}
                helperText={`network hospitals`}
                disableListView={
                  process.env.DISABLE_LISTVIEW === `true` ? true : false
                }
                showTypeInListView
              />
            </GridItem>
          </GridContainer>
        )}
      </div>
    );
  }
}
LandingSection.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.string,
  type: PropTypes.string,
  cards: PropTypes.array,
  description: PropTypes.string,
  sectionImage: PropTypes.string,
  cardsBackground: PropTypes.string,
  additionalDetails: PropTypes.object,
  background: PropTypes.bool
};

export default withStyles(featuresStyle)(LandingSection);
