import {
  container,
  mlAuto,
  mrAuto,
  title,
  cardTitle,
  description,
  coloredShadow,
  whiteColor,
  sectionDark,
  hexToRgb
} from "assets/jss/material-kit-pro-react.jsx";

import rotatingCards from "assets/jss/material-kit-pro-react/rotatingCards.jsx";

const plansSection = {
  container,
  mlAuto,
  mrAuto,
  title,
  description,
  coloredShadow,
  cardTitle,
  ...rotatingCards,
  textCenter: {
    textAlign: `center`
  },
  imghvr: {
    position: `relative`,
    "&:hover $onHover": {
      paddingTop: `35%`,
      height: `100%`
    },
    "&:hover $frontTitle": {
      // height: "0",
      top: `0`,
      paddingTop: `20%`
    }
  },
  onHover: {
    position: `absolute`,
    right: 0,
    left: 0,
    bottom: 0,
    height: 0,
    transition: `.5s ease`,
    overflow: `hidden`
  },
  projects: {
    padding: `80px 0`
  },
  tabSpace: {
    padding: `20px 0 50px 0px`
  },
  cardCategory: {
    color: `rgba(` + hexToRgb(whiteColor) + `, 0.7) !important`,
    marginTop: `10px`
  },
  cardTitleWhite: {
    ...cardTitle,
    color: whiteColor + `  !important`,
    marginTop: `10px !important`
  },
  cardDescription: {
    color: `rgba(` + hexToRgb(whiteColor) + `, 0.7) !important`
  },
  sectionSpace: {
    height: `70px`,
    display: `block`
  },
  marginTop20: {
    marginTop: `20px`
  },
  card2: {
    textAlign: `center`
  },
  sectionDark: {
    ...sectionDark,
    backgroundSize: `550% 450%`,
    "& $title, & $cardTitle": {
      color: whiteColor
    },
    "& $cardCategory": {
      color: `rgba(` + hexToRgb(whiteColor) + `, 0.5) !important`
    },
    "& $cardDescription": {
      color: `rgba(` + hexToRgb(whiteColor) + `, 0.76) !important`
    }
  },
  projects3: {
    "& $title": {
      marginBottom: `80px`,
      marginTop: `5px`
    },
    "& h6": {
      marginBottom: `5px`
    }
  },
  card4: {
    marginTop: `0`,
    marginBottom: `0`,
    "& $cardTitle": {
      color: whiteColor
    }
  },
  cardBody4: {
    paddingTop: `140px`,
    paddingBottom: `140px`
  },
  info4: {
    padding: `0`
  },
  projects4: {
    "& hr": {
      margin: `70px auto`,
      maxWidth: `970px`
    }
  },
  cardWithHover: {
    position: `relative`,
    width: `50%`,
    "&:hover $cardWithHoverOverlay": {
      height: `100%`
    }
  },

  cardWithHoverImg: {
    display: `block`,
    width: `100%`,
    height: `auto`
  },

  cardWithHoverOverlay: {
    position: `absolute`,
    bottom: `0`,
    left: `0`,
    right: `0`,
    backgroundColor: `rgba(0,0,0,0.7)`,
    overflow: `hidden`,
    width: `100%`,
    height: `0`,
    transition: `.10s ease`
  },

  cardWithHoverText: {
    color: `white`,
    fontSize: `20px`,
    position: `absolute`,
    top: `50%`,
    left: `50%`,
    "-webkit-transform": `translate(-50%, -50%)`,
    "-ms-transform": `translate(-50%, -50%)`,
    transform: `translate(-50%, -50%)`,
    textAlign: `center`
  },
  frontTitle: {
    position: `absolute`,
    height: `auto`,
    top: `60%`,
    width: `100%`,
    left: `0`,
    textAlign: `center`,
    transition: `.5s ease`,
    overflow: `hidden`
  }
};

export default plansSection;
