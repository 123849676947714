import {
  container,
  title,
  main,
  whiteColor,
  primaryColor,
  mainRaised,
  grayColor
} from "assets/jss/material-kit-pro-react.jsx";
import customSelectStyle from "assets/jss/material-kit-pro-react/customSelectStyle.jsx";

const landingPageStyle = theme => ({
  ...customSelectStyle,
  container: {
    color: whiteColor,
    ...container,
    zIndex: `2`
  },
  formMargin: {
    marginTop: `7px !important`,
    marginBottom: `15px !important`
  },
  socialIcon: {
    "& svg": {
      width: `100%`,
      height: `100%`,
      "& path": {
        fill: primaryColor[0]
      }
      // [theme.breakpoints.down(`xs`)]:{
      //   "& path": {
      //     fill: whiteColor
      //   },
      // }
    }
  },
  title: {
    ...title,
    display: `inline-block`,
    position: `relative`,
    marginTop: `30px`,
    minHeight: `32px`,
    fontSize: `3rem`,
    textDecoration: `none`,
    "@media (min-width: 767px) and (max-width: 768px)": {
      color: whiteColor
    },
    [theme.breakpoints.down(`xs`)]: {
      fontSize: `2rem`,
      // color: "black",
      // marginTop: `40px !important`,
      marginBottom: `0px !important`
    }
  },
  protectfamilystyle:{
    "@media (max-width: 500px) ": {
      width:'90% ',
      height:'28px '
    },
    "@media (max-width: 350px) ": {
      width:'95% !important',
      height:'22px !important'
    },
  },
  summary: {
    color: grayColor[1],
    maxWidth: `600px`,
    "@media (min-width: 767px) and (max-width: 768px)": {
      color: whiteColor
    },
    [theme.breakpoints.down(`xs`)]: {
      fontSize: `1.1rem`
    }
  },
  bannerPara: {
    fontSize:"25px",
    marginTop:"20px",
    "@media (max-width:600px)": {
      fontSize: '18px',
      marginTop: '10px'
    }
  },
  summary1: {
    color: 'white',
    maxWidth: `600px`,
    "@media (min-width: 767px) and (max-width: 768px)": {
      color: whiteColor
    },
    [theme.breakpoints.down(`xs`)]: {
      fontSize: `1.1rem`
    }
  },
  summary2: {
    color: '#384858',
    maxWidth: `600px`,
    "@media (min-width: 767px) and (max-width: 768px)": {
      color: whiteColor
    },
    [theme.breakpoints.down(`xs`)]: {
      fontSize: `1.1rem`
    }
  },
  subtitle: {
    fontSize: `1.313rem`,
    maxWidth: `500px`,
    margin: `10px auto 0`
  },
  main: {
    ...main
  },
  mainRaised: {
    ...mainRaised
  },
  block: {
    color: `inherit`,
    padding: `0.9375rem`,
    fontWeight: `500`,
    fontSize: `12px`,
    textTransform: `uppercase`,
    borderRadius: `3px`,
    textDecoration: `none`,
    position: `relative`,
    display: `block`
  },
  inlineBlock: {
    display: `inline-block`,
    padding: `0px`,
    width: `auto`
  },
  list: {
    marginBottom: `0`,
    padding: `0`,
    marginTop: `0`
  },
  left: {
    float: `left!important`,
    display: `block`
  },
  right: {
    padding: `15px 0`,
    margin: `0`,
    float: `right`
  },
  icon: {
    width: `18px`,
    height: `18px`,
    top: `3px`,
    position: `relative`
  },
  textLeft: {
    textAlign: `left`
  },
  h2Title:{
    color: `#3C4858`,
    fontSize:'1.5rem !important',
    textDecoration: `none`,
    fontWeight: `700`,
    marginTop: `30px`,
    marginBottom: `25px`,
    minHeight: `32px`,
    fontFamily: `"Roboto Slab", "Times New Roman", serif`,
    [theme.breakpoints.down('md')]: {
    color: `#3C4858 !important`,
    textDecoration: `none !important`,
    fontWeight: `700 !important`,
    marginTop: `30px !important`,
    marginBottom: `25px !important`,
    minHeight: `32px !important`,
    fontFamily: `"Roboto Slab", "Times New Roman", serif !important`,
    fontSize:'28px !important'
  }
  },
  pageHeader: {
    position: `relative`,
    height: `100vh`,
    maxHeight: `1600px`,
    backgroundPosition: `50%`,
    backgroundSize: `cover`,
    backgroundColor: `rgba(0,0,0,0.2)`,
    margin: `0`,
    padding: `0`,
    border: `0`,
    display: `flex`,
    paddingTop: `20px`,
    paddingLeft: `20px`,
    transition: `all 1s ease-in-out`,
    marginTop: `2%`,
    "&:hover": {
      backgroundColor: `transparent`
    },
    "@media (min-width: 767px) and (max-width: 768px)": {
      "&:hover": {
        backgroundColor: `rgba(0,0,0,0.2)`
      }
    },
    "@media (max-width: 1024px)": {
      backgroundPosition: `100%`,
      height: `auto !important`
    },
    "& > div:first-of-type": {
      width: `100% !important`
    },
    [theme.breakpoints.down(`md`)]: {
      marginTop: `7%`
    },
    [theme.breakpoints.down(`sm`)]: {
      paddingRight: `20px`,
      height: `auto !important`
    }
  },
  noBorder: {
    "&, &:hover": {
      borderWidth: `1px 0px 1px 0`,
      borderStyle: `solid`,
      borderColor: primaryColor[0],
      color: primaryColor[0],
      borderRadius: `0px 5px 5px 0px`,
      "@media (max-width:600px)": {
        borderWidth: `1px 1px 1px 1px`,
        borderStyle: `solid`,
        borderColor: primaryColor[0],
        color: primaryColor[0],
        borderRadius: `5px 5px 5px 5px`
      }
    }
  },
  allBorder:{
    "&, &:hover": {
      borderWidth: `1px 1px 1px 1px`,
      borderStyle: `solid`,
      borderColor: primaryColor[0],
      color: primaryColor[0],
      borderRadius: `0px 5px 5px 0px`,
      "@media (max-width:600px)": {
        borderWidth: `1px 1px 1px 1px`,
        borderStyle: `solid`,
        borderColor: primaryColor[0],
        color: primaryColor[0],
        borderRadius: `5px 5px 5px 5px`
      }
    }
  },
  noLeftBorder: {
    "&, &:hover": {
      borderWidth: `1px 1px 1px 0`,
      borderStyle: `solid`,
      borderColor: primaryColor[0],
      color: primaryColor[0],
      borderRadius: `0px 5px 5px 0px`
    }
  },
  noRightBorder: {
    "&, &:hover": {
      borderWidth: `1px 0 1px 1px`,
      borderStyle: `solid`,
      borderColor: primaryColor[0],
      color: primaryColor[0],
      borderRadius: `5px 0px 0px 5px`
    }
  },
  whiteBorder: {
    borderWidth: `1px`,
    borderStyle: `solid`,
    borderColor: `#FFFFFF`,
    color: `#FFFFFF`,
    width: `100%`
  },
  primaryBorder: {
    borderWidth: `1px`,
    borderStyle: `solid`,
    borderColor: primaryColor[0],
    color: primaryColor[0],
    "&:hover": {
      color: primaryColor[0]
    }
    // borderRadius: `5px 0px 0px 5px`
  },
  buttonPadding: {
    [theme.breakpoints.up(`sm`)]: {
      [theme.breakpoints.down(`md`)]: {
        padding: `0`
      }
    }
  },
  buttonMargin: {
    [theme.breakpoints.up(`lg`)]: {
      marginLeft: `-28px`
    }
  },
  portButtonMargin: {
    [theme.breakpoints.up(`lg`)]: {
      marginLeft: `-58px`
    }
  },
  cardStyle: {
    boxShadow: `none !important`,
    border: `1px solid #eee`
  },
  leftSectionsBackground: {
    backgroundPosition: `-6%`,
    [theme.breakpoints.down(`sm`)]: {
      backgroundPosition: `-16%`
    },
    [theme.breakpoints.down(`xs`)]: {
      backgroundPosition: `-96%`
    }
  },
  rightSectionsBackground: {
    backgroundPosition: `106%`,
    [theme.breakpoints.down(`sm`)]: {
      backgroundPosition: `116%`
    },
    [theme.breakpoints.down(`xs`)]: {
      backgroundPosition: `196%`
    }
  },
  bannerText: {
    maxWidth: `700px`,
    marginTop:'0px'
  },
  bannerText1: {
    display: 'flex',
    alignItems: 'center !important',
    justifyContent: 'center !important',
    flexDirection: 'column',
    // maxWidth: `800px`,
    minHeight: '73vh !important'
  },
  bannerPara1: {
    display: 'flex',
    alignItems: 'center !important',
    justifyContent: 'center !important',
    flexDirection: 'column',
    marginTop: '2%'
  },
  exceptCorona: {
    marginTop:'28px', 
    lineHeight:'55px',
    "@media (max-width:600px)": {
      lineHeight: '40px'
    }
  },
  coronaTitle: {
    fontSize:"65px", 
    marginTop:"28px",
    marginBottom:'0px',
    "@media (max-width:600px)": {
      fontSize: `2rem`,
      marginTop:"0px",
    },
    "@media (max-width: 768px)": {
      marginTop:"0px"
      }
  },
  coronaBannerText: {
    marginTop: `8%`,
    maxWidth: `52%`,
    fontSize: `36px`,
    "@media (max-width:736px)": {
      maxWidth: `700px`
    }
  },
  coronaContent: {
    fontSize:"22px", 
    lineHeight:"30px",
    fontWeight:"150", 
    marginTop:"2px"
  },
  knowMoreButton: {
    marginTop:'40px',
    "@media (max-width:600px)": {
      marginTop:"3px",
    },
    "@media (max-width: 768px)": {
      marginTop:"3px"
      }
  },
  portPolicyButton:{
    [theme.breakpoints.down(`md`)]:{
      marginLeft:`auto`,
      marginRight:`auto`
    }
  },
  coronaPlans:{
    [theme.breakpoints.up(`md`)]:{
      marginLeft:`5%`,
      marginTop:`8% !important`,
      "& h1":{
        fontSize: `65px`
      }
    },
    [theme.breakpoints.down(`sm`)]:{
      "& h1":{
        marginBottom:`5% !important`,
        marginTop:`0px !important`
      }
    }
  },
  hidden:{
    display: `block`
  },
  bannerParastyle:{
    fontSize:'24px',
    "@media (max-width:700px)": {
      fontSize:'18px'
    },
  }
});

export default landingPageStyle;
