// ##############################
// // // Variables - Styles that are used on more than one component
// #############################

const statsColors = {
  primary: {
    backgroundColor: `#35488a`
  },
  secondary: {
    backgroundColor: `#6d028d`
  },
  accent: {
    backgroundColor: `#e91e63`
  },
  blue: {
    backgroundColor: `#2c92c7`
  },
  roundedTabButton: {
    width: 165,
    height: 40,
    color: `#35488a`,
    background: `transparent`,
    border: `solid 1px #35488a`,
    fontSize: 16,
    cursor: `pointer`
  },
  roundedTabButtonActive: {
    color: `#FFFFFF`,
    backgroundColor: `#35488a`
  }

};

export {
  //variables
  statsColors
};
