/* eslint-disable */
import React from 'react'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// core components

import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import plansStyle from 'ps-assets/jss/plansStyle.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Badge from 'components/Badge/Badge.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import forCorporate from 'ps-assets/img/for-corporate.png'
import { Link } from 'gatsby'
import {sevaApp} from '../../util/LayoutUtil'

const plans = [
  {
    id: 'forMe',
    title: 'For You',
    image: require(`ps-assets/img/for-myself.png`),
    image1: require(`ps-assets/img/for-myself-clr.png`),
    description: 'Obviously, you are important.',
    tags: [
      {
        id: 'health',
        title: 'Health',
        color: 'rose',
        url: '/comprehensive-health-insurance-plan?noOfAdults=1&noOfChildren=0'
      },
      {
        id: 'travel',
        title: 'Travel',
        color: 'warning',
        url: '/international-individual-travel-insurance'
      },
      {
        id: 'accident',
        title: 'Accident',
        color: 'danger',
        url: '/accident-insurance/individual-accident-insurance-policy'
      }
    ]
  },
  {
    id: 'forMyFamily',
    title: 'For Your Family',
    image: require(`ps-assets/img/for-family.png`),
    image1: require(`ps-assets/img/for-family-clr.png`),
    description:
      "Health is Happiness.",
    tags: [
      {
        id: 'health',
        title: 'Health',
        color: 'rose',
        url: '/comprehensive-health-insurance-plan?noOfAdults=1&noOfChildren=1'
      },
      {
        id: 'travel',
        title: 'Travel',
        color: 'warning',
        url: '/travel-insurance-plans'
      },
      {
        id: 'accident',
        title: 'Accident',
        color: 'danger',
        url: '/family-accident-care'
      }
    ]
  },
  {
    id: 'forParents',
    title: 'For Your Parents',
    image: require(`ps-assets/img/for-parent.png`),
    image1: require(`ps-assets/img/for-parent-clr.png`),
    description: 'Truly caring.',
    tags: [
      {
        id: 'health',
        title: 'Health',
        color: 'rose',
        url: '/health-insurance-plan-for-senior-citizens?noOfAdultsForIndividual=2'
      },
      {
        id: 'travel',
        title: 'Travel',
        color: 'warning',
        url: '/travel-insurance-plans'
      },
      {
        id: 'accident',
        title: 'Accident',
        color: 'danger',
        url: '/accident-insurance/individual-accident-insurance-policy'
      }
    ]
  },
  {
    id: 'forCorporate',
    title: 'For Corporates',
    image: require(`ps-assets/img/for-corporate.png`),
    image1: require(`ps-assets/img/for-corporate-clr.png`),
    description: 'Customized covers just for you.',
    tags: [
      {
        id: 'health',
        title: 'Health',
        color: 'rose',
        url: 'https://retail.starhealth.in/landing/gmc/allpolicies/index.html'
      },
      {
        id: 'travel',
        title: 'Travel',
        color: 'warning',
        url: '/international-corporate-travel-insurance'
      },
      {
        id: 'accident',
        title: 'Accident',
        color: 'danger',
        url: '/group-accident-insurance-policy'
      }
    ]
  }
];

class SectionProjects extends React.Component {
  state = {};

  constructor(props) {
    super(props)
  }

  render() {
    const { classes, sectionTitle, sectionSubTitle } = this.props;

    return (
      <div className="cd-section">
        <div className={classes.projects}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem
                xs={12}
                sm={8}
                md={8}
                className={`${classes.mlAuto} ${classes.mrAuto} ${
                  classes.textCenter
                  }`}>
                <h1 className={classes.title} style={{fontSize:"36px"}}>{sectionTitle}</h1>
                <p className={classes.description} style={{fontSize:"18px",padding:'5px'}}>{sectionSubTitle}</p>
              </GridItem>
            </GridContainer>
            <GridContainer>
              {plans.map(plan => {
                return (
                  <GridItem xs={12} sm={6} md={3} key={`plancontainer-${plan.id}`}>
                    <div
                      className={classes.rotatingCardContainer}
                      onMouseEnter={() => this.setState({ [plan.id]: true })}
                      onMouseLeave={() => this.setState({ [plan.id]: false })}>
                      <Card
                        raised={this.state[plan.id]}
                        background
                        style={{
                          backgroundImage: `${
                            this.state[plan.id]
                              ? `url(${plan.image1})`
                              : `url(${plan.image})`
                            }`
                        }}>
                        <div
                          className={`${classes.front} ${
                            classes.wrapperBackground
                            } ${
                            this.state[plan.id]
                              ? classes.wrapperBackgroundOnHover
                              : ''
                            } ${classes.imghvr}`}
                        >
                          <CardBody
                            background
                            className={classes.cardBodyRotate}
                            style={{
                              minHeight: 280,
                              justifyContent: this.state[plan.id] ? 'center' : 'flex-end',
                            }}>

                            <a
                            rel="noopener noreferrer"
                              href="#pablo"
                              onClick={e => e.preventDefault()} className={classes.frontTitle}>
                              <h3 className={classes.cardTitleWhite}>
                                {plan.title}
                              </h3>
                            </a>

                            {/*This section need to ease in or fade in with transition*/}

                            <div className={classes.onHover}>
                              <h3 className={classes.cardTitleWhite}>
                                {/* {plan.title} */}
                              </h3>
                              <h5
                                style={{
                                  color: '#FFFFFF',
                                  marginBottom: "30px",
                                  minHeight: "80px"
                                }}>
                                {plan.description}
                              </h5>
                              {plan.tags && plan.tags.length > 0 && (
                                <p>
                                  {plan.tags.map((tag,i) => {
                                   if (
                                      tag.url &&
                                      tag.url.includes('https://')
                                    ) {
                                      return (
                                        <a rel="noopener" href={(sevaApp()&& tag.url.includes("?"))? tag.url+'&sevaapp=true':(sevaApp() && (!tag.url.includes("?")))? tag.url+'?sevaapp=true':tag.url} target="_blank" key={`plan-${i}`}>
                                          <Badge
                                            color={tag.color}
                                            key={tag.id}>
                                            {tag.title}
                                          </Badge>
                                        </a>
                                      )
                                    } else {
                                      return (
                                        <Link to={(sevaApp()&& tag.url.includes("?"))? tag.url+'&sevaapp=true':(sevaApp() && (!tag.url.includes("?")))? tag.url+'?sevaapp=true':tag.url} key={`plan-${i}`}>
                                          <Badge
                                            color={tag.color}
                                            key={tag.id}>
                                            {tag.title}
                                          </Badge>
                                        </Link>
                                      )
                                    }
                                  })}
                                </p>
                              )}
                            </div>

                          </CardBody>
                        </div>
                      </Card>
                    </div>
                  </GridItem>
                )
              })}
            </GridContainer>
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(plansStyle)(SectionProjects)
