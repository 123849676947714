import {
  container,
  mlAuto,
  mrAuto,
  title,
  blackColor,
  whiteColor,
  grayColor,
  hexToRgb,
  primaryColor,
  coloredShadow,
  cardTitle
} from "assets/jss/material-kit-pro-react.jsx";

import { statsColors } from "ps-assets/jss/starCore.jsx";

import rotatingCards from "assets/jss/material-kit-pro-react/rotatingCards.jsx";

const customer_app_icon_dimensions = {
  img_responsivity: {
    "768px": {
      width: `60%`,
      height: `60%`
    },
    "420px": {
      width: `80%`,
      height: `80%`
    },
    "350px": {
      width: `70%`,
      height: `70%`
    },
    "300px": {
      width: `60%`,
      height: `60%`
    }
  },
  div_responsivity: {
    "300px": {
      marginTop: `22%`
    },
    "340px": {
      marginTop: `25%`
    },
    "450px": {
      marginTop: `27%`
    },
    "750px": {
      marginTop: `30%`
    }
  }
};

const featuresStyle = theme => ({
  container,
  mlAuto,
  mrAuto,
  coloredShadow,
  ...rotatingCards,
  statsCard: {
    fontFamily: `Roboto  !important`,
    "& div h1": {
      fontFamily: `Roboto  !important`,
      fontWeight: `100`
    },
    "& div h5": {
      fontFamily: `Roboto  !important`,
      fontWeight: `100`
    },
    "& div h6": {
      fontFamily: `Roboto  !important`,
      fontWeight: `100`
    }
  },
  awardHeader: {
    [theme.breakpoints.down(`sm`)]: {
      textAlign: `center`,
      paddingTop: `10px`,
      paddingBottom: `10px`
    }
  },
  customCardTitleWhite: {
    fontFamily: `Roboto  !important`,
    fontWeight: `100`,
    fontSize: `3.3em`,
    lineHeight: `1`,
    color: whiteColor,
    paddingTop: `26px`,

    "& div p": {
      fontFamily: `Roboto  !important`,
      fontWeight: `100`
    }
  },
  customCardTitle: {
    ...cardTitle,
    fontFamily: `Roboto !important`,
    fontWeight: `100 !important`
  },
  callBackButton: {
    "& svg": {
      width: `100%`,
      height: `100%`
    }
  },
  customCard: {
    boxShadow: `none`,
    border: `solid 1px #eee`,
    "&:hover": {
      border: `none`,
      boxShadow: `0 4px 20px 0px rgba(63, 81, 181, 0.14), 0 7px 10px -5px rgba(255, 255, 255, 0.4)`
    },
    "&:hover svg path": {
      fill: whiteColor
    }
  },
  featureEffect: {
    transition: `all .7s ease-in`,
    "&:hover": {
      color: whiteColor + `! important`,
      backgroundColor: primaryColor[0],
      boxShadow: `0 4px 20px 0px rgba(63, 81, 181, 0.4), 0 7px 10px -5px rgba(255, 255, 255, 0.4)`
    },
    "&:hover h6": {
      color: whiteColor + `! important`
    },
    "&:hover p": {
      color: whiteColor + `! important`
    }
  },
  section: {
    paddingBottom: `20px`,
    textAlign: `center`
  },
  title: {
    ...title,
    marginBottom: `1rem`,
    marginTop: `0px`,
    minHeight: `32px`,
    textDecoration: `none`
  },
  cardTitleWhite: {
    ...cardTitle,
    color: whiteColor + `  !important`
  },
  description: {
    color: grayColor[1],
    [theme.breakpoints.down(`xs`)]: {
      textAlign: `center !important`
      // marginLeft: "auto",
      // marginRight: "auto"
    }
  },
  features1: {
    textAlign: `center`,
    padding: `80px 0`
  },
  features2: {
    padding: `80px 0`
  },
  features3: {
    padding: `70px 0 50px 0`,
    "& $phoneContainer": {
      margin: `0 auto`
    },
    "& .slick-track": {
      marginBottom: 50
    }
  },
  features4: {
    padding: `80px 0`,
    "& $phoneContainer": {
      maxWidth: `260px`,
      margin: `60px auto 0`
    }
  },
  features5: {
    padding: `80px 0`,
    backgroundSize: `cover`,
    backgroundPosition: `50%`,
    backgroundRepeat: `no-repeat`,
    position: `relative`,
    "& $title": {
      marginBottom: `30px`
    },
    "& $title,& $container": {
      position: `relative`,
      zIndex: `2`,
      color: whiteColor
    },
    "&:after": {
      background: `rgba(` + hexToRgb(blackColor) + `,0.55)`,
      position: `absolute`,
      width: `100%`,
      height: `100%`,
      content: `''`,
      zIndex: `0`,
      left: `0px`,
      top: `0px`
    },
    "& $container": {
      "& $gridContainer:last-child": {
        "& $gridItem": {
          borderBottom: `0`
        }
      },
      "& $gridItem": {
        border: `1px solid rgba(` + hexToRgb(whiteColor) + `, 0.35)`,
        borderTop: `0`,
        borderLeft: `0`,
        "&:last-child": {
          borderRight: `0`
        }
      }
    },
    "& $infoArea5": {
      textAlign: `center`,
      maxWidth: `310px`,
      minHeight: `320px`,
      "& h4,& p,& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
        color: whiteColor
      }
    }
  },
  gridContainer: {},
  gridItem: {},
  textCenter: {
    textAlign: `center`
  },
  phoneContainer: {
    "& img": {
      width: `100%`,
      height: `100%`
    }
  },
  infoArea: {
    maxWidth: `none`,
    margin: `0 auto`,
    padding: `10px 10px 10px`,
    "& $infoAreaWithWhiteBackground": {}
  },
  infoAreaWithWhiteBackground: {
    background: `#FFFFFF`
  },
  trendingCard: {
    minHeight: `320px`,
    [theme.breakpoints.up(`md`)]: {
      minHeight: `175px`
    }
  },
  responsiveCardTitle: {
    [theme.breakpoints.down(`xs`)]: {
      textAlign: `center !important`
      // marginLeft: "auto",
      // marginRight: "auto"
    }
  },
  customerBanner: {
    margin: `0 auto`,
    width: `100%`,
    borderRadius: `6px`,
    zIndex: `0000`
  },
  playstoreIcon: {
    float: `left`,
    marginTop: `27%`,
    zIndex: `99999`,
    position: `absolute`,
    marginLeft: `5%`,
    "@media (max-width:750px)": {
      ...customer_app_icon_dimensions.div_responsivity[`750px`]
    },
    "@media (max-width:450px)": {
      ...customer_app_icon_dimensions.div_responsivity[`450px`]
    },
    "@media (max-width:340px)": {
      ...customer_app_icon_dimensions.div_responsivity[`340px`]
    },
    "@media (max-width:300px)": {
      ...customer_app_icon_dimensions.div_responsivity[`300px`]
    },
    "& a": {
      marginLeft: 20,
      marginRight: 20,
      "& img": {
        "@media (max-width:767px) and (min-width:751px)": {
          ...customer_app_icon_dimensions.img_responsivity[`768px`]
        },
        "@media (max-width:420px)": {
          ...customer_app_icon_dimensions.img_responsivity[`420px`]
        },
        "@media (max-width:350px)": {
          ...customer_app_icon_dimensions.img_responsivity[`350px`]
        },
        "@media (max-width:300px)": {
          ...customer_app_icon_dimensions.img_responsivity[`300px`]
        }
      }
    }
  },
  appStoreIcon: {
    float: `left`,
    marginTop: `27%`,
    zIndex: `99999`,
    position: `absolute`,
    marginLeft: `35%`,
    "@media (max-width:750px)": {
      ...customer_app_icon_dimensions.div_responsivity[`750px`]
    },
    "@media (max-width:450px)": {
      ...customer_app_icon_dimensions.div_responsivity[`450px`]
    },
    "@media (max-width:340px)": {
      ...customer_app_icon_dimensions.div_responsivity[`340px`]
    },
    "@media (max-width:300px)": {
      ...customer_app_icon_dimensions.div_responsivity[`300px`]
    },
    "& a": {
      marginLeft: 20,
      marginRight: 20,
      "& img": {
        "@media (max-width:767px) and (min-width:751px)": {
          ...customer_app_icon_dimensions.img_responsivity[`768px`]
        },
        "@media (max-width:420px)": {
          ...customer_app_icon_dimensions.img_responsivity[`420px`]
        },
        "@media (max-width:350px)": {
          ...customer_app_icon_dimensions.img_responsivity[`350px`]
        },
        "@media (max-width:300px)": {
          ...customer_app_icon_dimensions.img_responsivity[`300px`]
        }
      }
    }
  },
  infoArea5: {},
  ...statsColors,
  monoChrome:{
    filter : `grayscale(1)`,
    '&:hover':{
      filter : `grayscale(0)`
    }
  }
});

export default featuresStyle;
