const plansStyle = function() {
    return {
        sectionBackground: {
            backgroundColor: `#f2f4f7`,
            color: `black`,
            display: `flex`,
            flexDirection: `column`,
            "@media (max-width: 1000px)": {
                fontSize: `14px`,
            },
            "@media (max-width: 600px)": {
                fontSize: `12px`,
            },
        },
        navList: {
            display: `flex`,
            flexDirection: `row`,
            margin: `2rem auto 2rem auto`,
            columnGap: `0.3rem`,
            backgroundColor: `#d7dee8`,
            padding: `0.3rem`,
            borderRadius:`0.3rem`,
        },
        navButton: {
            fontSize: `1.1em`,
            fontWeight: `600`,
            color: `rgb(33, 36, 60)`,
            border: `none`,
            padding: `0.7rem 2rem 0.7rem 2rem`,
            borderRadius:`0.3rem`,
            "&:hover": {
                cursor: `pointer`,
                backgroundColor: `white`,
            }
        },
        navButtonActive: {
            backgroundColor: `white`,
            color: `blue`,
        },
        planCard: {
            backgroundColor: `#e5eaf0`,
            width: `65%`,
            margin: `0 auto 2rem auto`,
            padding: `1rem`,
            borderRadius: `0.4rem`,
            border: `1px solid #f2f4f7`,
            color: `#3C4858`,
            "& section": {
                display: `flex`,
                flexDirection: `row`,
                width: `100%`,
                justifyContent: `space-between`,
                "& h1": {
                    fontSize: `1.8em`,
                    fontWeight: `600`,
                },
                "& h2": {
                    fontSize: `1.8em`,
                    fontWeight: `600`,
                },
                "& p": {
                    fontSize: `1.15em`
                },
                "@media (max-width: 600px)": {
                    flexDirection: `column`,
                },
            },
            "@media (max-width: 1000px)": {
                width: `90%`,
            },
        },
        planImage: {
            flexBasis: `30%`,
            // aspectRatio: `1.5`,
        },
        planSummary: {
            width: `65%`,
            "@media (max-width: 600px)": {
                width: `100%`,
                order: `2`,
            },
        },
        ctaButton: {
            border: `none`,
            backgroundColor: `orange`,
            color: `white`,
            padding: `0.4rem 0.8rem`,
            borderRadius: `0.3rem`,
            fontSize: `1rem`,
            fontWeight: `700`,
            margin: `1rem 0 0.5rem 3%`,
            "&:hover": {
                cursor: `pointer`,
                backgroundColor: `#F28C28`,
            },
        }
    }
}
export default plansStyle;