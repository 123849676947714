import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import ReactSVG from "react-svg";
import infoStyle from "assets/jss/material-kit-pro-react/components/infoStyle.jsx";
import CreditCard from "@material-ui/icons/CreditCard";

function PsInfoArea({ ...props }) {
  const { classes, title, description, iconColor, vertical, className } = props;
  const iconWrapper = classNames({
    [classes.iconWrapper]: true,
    [classes[iconColor]]: true,
    [classes.iconWrapperVertical]: vertical
  });
  const iconClasses = classNames({
    [classes.icon]: true
  });
  const infoAreaClasses = classNames({
    [classes.infoArea]: true,
    [className]: className !== undefined
  });
  let icon = null;
  switch (typeof props.icon) {
    case `string`:
      icon = <ReactSVG className={iconClasses} src={props.icon} style={{ width: `36px`, height: `36px`}} />;
      break;
    default:
      icon = <CreditCard style={{ width: `36px`, height: `36px` }} />;
      break;
  }
  return (
    <div className={infoAreaClasses}>
      <div className={iconWrapper}>{icon}</div>
      <div className={classes.descriptionWrapper}>
        <h3 className={classes.title}>{title}</h3>
        <div className={classes.description}><p>{description}</p></div>
      </div>
    </div>
  );
}

PsInfoArea.defaultProps = {
  iconColor: `gray`
};

PsInfoArea.propTypes = {
  classes: PropTypes.object.isRequired,
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.string]).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.node.isRequired,
  iconColor: PropTypes.oneOf([
    `primary`,
    `warning`,
    `danger`,
    `success`,
    `info`,
    `rose`,
    `gray`
  ]),
  vertical: PropTypes.bool,
  className: PropTypes.string
};

export default withStyles(infoStyle)(PsInfoArea);
