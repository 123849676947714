import React from "react";
import "assets/scss/material-kit-pro-react.scss?v=1.3.0";
import classNames from "classnames";
import root from "window-or-global";
import PropTypes from "prop-types";
import Layout from "ps-components/Layout/Layout.jsx";
import landingPageStyle from "ps-assets/jss/landingPageStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "../components/Grid/GridContainer";
import GridItem from "../components/Grid/GridItem";
import Button from "../components/CustomButtons/Button.jsx";
import { graphql, Link, withPrefix } from "gatsby";
import Plans from "templates/sections/Plans.jsx";
import LandingPagePlans from "templates/sections/LandingPagePlans";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Testimonials from "templates/sections/Testimonials.jsx";
import LandingSection from "templates/sections/LandingSection.jsx";
import FormControl from "@material-ui/core/FormControl";
import protectingFamily from "ps-assets/img/protecting-family.png";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Img from "gatsby-image";
import { Helmet } from "react-helmet";
// react component for creating beautiful carousel

// import LazyLoad from "react-lazyload";
// for handling media queries
import MediaQuery from "react-responsive";
import Carousel from "react-slick";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from "react-share";
import ReactSVG from "react-svg";
import PortabilityForm from "templates/PortabilityForm";
import { browserWindow } from "util/localStorageHelper";
import Hidden from "@material-ui/core/Hidden";
import { sevaApp } from 'util/LayoutUtil.jsx';
// import { wizardLMSCall } from "util/lmsRequest";
const stringSearchRegExp = /<stringNetworkHospitalCount \/>/g
const STRHOSPITALCOUNT = process.env.STRHOSPITALCOUNT;
const CUSTOMERPORTAL_URL = process.env.CUSTOMERPORTAL_URL

const getLazyLoadComponent = (component) => {
  return (
    <>
      <div>{component}</div>
      {/* <LazyLoad key={key}>{component}</LazyLoad> */}
    </>
  );
};

const stats = {
  additionalDetails: {
    button: {
      text: `Know More`,
      color: `primary`
    }
  },
  cards: [
    {
      id: `1`,
      title: `16.9 Cr`,
      style: {
        minHeight: `210px`
      },
      description: `lives covered since inception`,
      color: `primary`,
      gridSize: 5
    },
    {
      id: `2`,
      title: `₹ 30,385 Cr`,
      style: {
        minHeight: `210px`
      },
      description: `claim amount paid out since inception`,
      color: `blue`,
      gridSize: 7,
      image: {
        src: require(`ps-assets/img/why-star1.png`),
        position: `left`,
        gridSize: 4
      }
    },
    {
      id: `3`,
      title: `HASSLE–FREE `,
      style: {
        minHeight: `210px`
      },
      color: `blue`,
      description: `in-house claims settlement`,
      gridSize: 5
    },
    {
      id: `4`,
      icon: require(`ps-assets/img/star-award-white.png`),
      title: `Health Insurance Provider of the year award`,
      style: {
        minHeight: `210px`
      },
      color: `primary`,
      description: ``,
      gridSize: 2
    },
    {
      id: `5`,
      title: `5.5 LAKH+`,
      titleSize: `sm`,
      style: {
        minHeight: `210px`
      },
      color: `accent`,
      description: `agents, building a healthier India`,
      gridSize: 5
    },
    {
      id: `6`,
      title: `89.9%`,
      style: {
        minHeight: `210px`
      },
      description: `cashless claims settled in less than 2 hours`,
      color: `blue`,
      gridSize: 2
    },
    {
      id: `7`,
      title: STRHOSPITALCOUNT,
      style: {
        minHeight: `210px`
      },
      description: `strong network hospitals at your service`,
      color: `accent`,
      gridSize: 3
    },
    {
      id: `8`,
      color: `primary`,
      image: {
        src: require(`ps-assets/img/why-star2.png`),
        position: `right`,
        gridSize: 4
      },
      title: `75 LAKH+`,
      style: {
        minHeight: `210px`
      },
      description: `claims settled since inception`,
      gridSize: 7
    }
  ]
};

function lSections(id, cards, title, summary, additionalDetails = {}, plansData) {
  switch (id) {
    case 2:
      // Plan Section - Plan for Myself, Plan for Family..
      return getLazyLoadComponent(
          <Plans
            sectionTitle={title}
            sectionSubTitle={summary}
            key={`plans-${id}`}
          />,
          `lazyload-plans-${id}`
      );

    case 3:
      // Quick Links Section - File a Claim, Check Claim Status...
      return (
        <LandingSection
          cards={cards}
          title={title}
          type="onlyCardsWithFeature"
          key={`lazyload-quicklinks-${id}`}
        />
      );
    
    case 4:
      //What's Trending Section - Star Special Care..
      return getLazyLoadComponent(
          <LandingSection
            cards={cards}
            title={title}
            type="onlyCardsWithImageOnRight"
            additionalDetails={additionalDetails}
          />,
          `lazyload-trending`
      );
    case 5:
      // Stasts Card Section - 6.34 lakhs claims settled...
      return getLazyLoadComponent(
          <LandingSection
            cards={stats.cards}
            title="Why Star Health Insurance is a Smart choice"
            type="cardsWithStats"
            additionalDetails={stats.additionalDetails}
          />,
          `lazyload-stats`
      );
    case 6:
      // Key Feature Section - Hassle Free Claims
      return getLazyLoadComponent(
          <LandingSection
            cards={cards}
            title={title}
            description={summary}
            type="imageWithCardsOnLeft"
            sectionImage={require(`ps-assets/img/hassle-free-claims.png`)}
            additionalDetails={additionalDetails}
          />,
          `lazyload-keyfeature`
      );
    case 7:
      // Network Hospitals Section - Display Map
      return getLazyLoadComponent(
          <LandingSection title={title} description={summary} type="map" />,
          `lazyload-network`
      );
    case 8:
      // Testimonials Section
      return getLazyLoadComponent(
          <Testimonials
            cards={cards.sort((a, b) => b.id - a.id)}
            title={title}
            description={summary}
          />,
          `lazyload-testimonials`
      );
    case 9:
      // Awards Section
      return getLazyLoadComponent(
          <LandingSection
            cards={cards}
            title={title}
            description={summary}
            type="imageWithImageCardsOnRight"
            cardsBackground="white"
            sectionImage={require(`ps-assets/img/award.png`)}
            additionalDetails={additionalDetails}
            key={`awardsContainer`}
          />,
          `lazyload-awards`
      );
      case 787:
      return getLazyLoadComponent(
        <LandingPagePlans plansData={plansData}/>,
        `lazyload-plans`
    );
  }
}

class IndexPage extends React.Component {
  settings = {
    dots: true,
    infinite: true,
    arrows: false,
    autoplaySpeed: 7000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    dotsClass: `vertical-dots`,
    adaptiveHeight: true,
    beforeChange: (current, next) => {
      this.currentSlide = next;
    }
  };
  currentSlide = 0;
  state = {
    hovered: false,
    mode: `buy`,
    form: {
      name: ``,
      renew: ``,
      policy: ``,
      insuranceType: ``
    },
    links: {
      myself: `/comprehensive-health-insurance-plan`,
      family: `/comprehensive-health-insurance-plan`,
      parents: `/health-insurance-plan-for-senior-citizens`
    },
    adults: {
      myself: `1`,
      family: `1`,
      parents: `2`
    },
    children: {
      myself: `0`,
      family: `1`,
      parents: `0`
    },
    webPSupport: true,
    submitStatus: false,
    signupModal: false,
  };

  constructor(props) {
    super(props);
  }

  updateSignUpModal = (x) => {
    this.setState(x);
  }

  handleClickOpen(modal) {
    var x = [];
    x[modal] = true;
    this.updateSignUpModal(x);
  }

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.updateSignUpModal(x);
  }

  setMode(mode) {
    this.setState({
      mode: mode
    });
  }

  setInputValueToState = (event, value) => {
    if(event.target.id == `name`){
      if(event.target.value[0] != ' '){
        this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [value]: event.target.value
      }
    });}
  }
    event.preventDefault();
  };

  renewPolicy = () => {
  };

  buy = () => {
  };
  handleSimple = event => {
    this.setState({
      form: { ...this.state.form, insuranceType: event.target.value }
    });
  };

  handleMouseOver = id => {
    this.setState({
      [id]: true
    });
  };
  handleBuyNow = () => {
    this.setState({
      submitStatus: true
    });
    // let { name } = this.state.form;
    // let urlInfo = browserWindow.location.href;
    // wizardLMSCall(
    //   name,
    //   null,
    //   null,
    //   null,
    //   null,
    //   urlInfo,
    // );
  };
  handleMouseLeave = id => {
    this.setState({
      [id]: false
    });
  };

  bannerContent = (item, matches, classes, bannerContentItemId, content) => (
    <div
      className={`${classes.container} ${item.id === 284 ? classes.coronaPlans : ``}`}
      style={{
        alignSelf: `center`,
        marginTop: bannerContentItemId && content == 'lgUp' ? `-35%` : `2%`,
      }}
    >
        {/*
        Hero Section
    */}
      {item.id !== 284 ? <div>
        {item.id == 1002 ? 
        <div>
          <Hidden smDown>
        <GridItem className={classes.bannerText1} >
          <h2
            className={
              classes.title +
              ` ` +
              classes.exceptCorona
            }
            style={{color: 'white',maxWidth: '700px',textAlign: 'center'}}
            // style={{marginTop:'20px', lineHeight:'55px'}}
          >
            {item &&
              item.title &&
              item.title.split(`\\n`).map((item, index) => {
                return (
                  <span key={`banner-` + index}>
                    {item}
                    <br />
                  </span>
                );
              })}
          </h2>
          <p className={classes.summary1 + ` ` + classes.bannerPara}>
          {item &&
              item.summary &&
              item.summary.replace(stringSearchRegExp, STRHOSPITALCOUNT).split(`\\n`).map((item, index) => {
                return (
                  <span key={`banner-` + index} className={classes.bannerPara1} >
                    {item}
                    <br />
                    <Button
                      style={{backgroundColor: 'orange', color: 'white',alignItems: 'center',marginTop: '7%'}}
                        href="https://lp1.starhealth.in/Gift-A-Healthy-Tomorrow/"
                        >Gift Now
                      </Button>
                  </span>
                );
              })}
          </p>
          <br />
        </GridItem>
        </Hidden>
        <Hidden mdUp>
        <GridItem className={classes.bannerText1} >
          <h2
            className={
              classes.title +
              ` ` +
              classes.exceptCorona
            }
            style={{color: '#384858',maxWidth: '700px',textAlign: 'center'}}
            // style={{marginTop:'20px', lineHeight:'55px'}}
          >
            {item &&
              item.title &&
              item.title.split(`\\n`).map((item, index) => {
                return (
                  <span key={`banner-` + index}>
                    {item}
                    <br />
                  </span>
                );
              })}
          </h2>
          <p className={classes.summary2 + ` ` + classes.bannerPara}>
          {item &&
              item.summary &&
              item.summary.replace(stringSearchRegExp, STRHOSPITALCOUNT).split(`\\n`).map((item, index) => {
                return (
                  <span key={`banner-` + index} className={classes.bannerPara1} >
                    {item}
                    <br />
                    <Button
                      style={{backgroundColor: 'orange', color: 'white',alignItems: 'center',marginTop: '7%'}}
                        href="https://lp1.starhealth.in/Gift-A-Healthy-Tomorrow/"
                        >Gift Now
                      </Button>
                  </span>
                );
              })}
          </p>
          <br />
        </GridItem>
        </Hidden>
      </div> : 
        <GridItem className={classes.textLeft}>
          <h2
            className={
              classes.title +
              ` ` +
              classes.bannerText +
              ` ` +
              classes.exceptCorona
            }
            // style={{marginTop:'20px', lineHeight:'55px'}}
          >
            {item &&
              item.title &&
              item.title.split(`\\n`).map((item, index) => {
                return (
                  <span key={`banner-` + index}>
                    {item}
                    <br />
                  </span>
                );
              })}
          </h2>
          <p className={classes.summary + ` ` + classes.bannerPara}>
            {item &&
              item.summary &&
              item.summary.replace(stringSearchRegExp, STRHOSPITALCOUNT).split(`\\n`).map((item, index) => {
                return (
                  <span key={`banner-` + index}>
                    {item}
                    <br />
                  </span>
                );
              })}
          </p>
          <br />
        </GridItem>
        }
      </div> :
      <GridContainer>
        <GridItem>
          <img src={protectingFamily} className={classes.protectfamilystyle} />
        </GridItem>
        <GridItem className={classes.textLeft}>
          <h2
            className={
              classes.title +
              ` ` +
              classes.bannerText + 
              ` ` +
              classes.coronaTitle
            }
          >
              Corona Kavach
          </h2>
            <p 
              className={
                classes.summary + 
                ` ` +
                classes.coronaContent
              }
              >
                A health insurance plan designed to safeguard you and your
                family from COVID-19.
              </p>
        </GridItem>
      </GridContainer> 
      }
      {item.id !== 284 && item.id !== 1002 ? (
        <>
          <GridContainer
            style={{
              justifyContent: `left`,
              marginTop: matches ? `0px` : `25px`
            }}
          >
            <GridItem
              xs={12}
              sm={3}
              md={3}
              lg={2}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter,
                classes.buttonPadding
              )}
            >
              <Button
                block
                color={
                  this.state.mode === `buy` ||
                  (item.sequence !== 2 && this.state.mode === ``)
                    ? `primary`
                    : `transparent`
                }
                className={`${classes.button} ${
                  this.state.mode !== `buy` ||
                  (item.sequence === 2 && this.state.mode === ``)
                    ? matches
                      ? classes.primaryBorder
                      : classes.allBorder
                    : ``
                }`}
                onClick={() => this.setMode(`buy`)}
              >
                I want to Buy
              </Button>
            </GridItem>
            <GridItem
              xs={12}
              sm={3}
              md={3}
              lg={2}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter,
                classes.buttonPadding
              )}
            >
              <Button
                value="i want to renew"
                aria-label="i want to renew"
                block
                color={
                  this.state.mode === `renew` ||
                  (item.sequence === 2 && this.state.mode === ``)
                    ? `primary`
                    : `transparent`
                }
                className={`${classes.button} ${classes.buttonMargin} ${
                  this.state.mode === `renew` ||
                  (item.sequence === 2 && this.state.mode === ``)
                    ? ``
                    : matches
                      ? classes.primaryBorder
                      : classes.noBorder
                }`}
                onClick={() => {
                  this.setMode(`renew`);
                }}
              >
                I want to Renew
              </Button>
            </GridItem>
            <GridItem
              xs={12}
              sm={3}
              md={3}
              lg={2}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter,
                classes.buttonPadding
              )}
            >
              <Button
                value="i want to port"
                aria-label="i want to port"
                block
                color={
                  this.state.mode === `port` ||
                  (item.sequence === 2 && this.state.mode === ``)
                    ? `primary`
                    : `transparent`
                }
                className={`${classes.button} ${classes.portButtonMargin} ${
                  this.state.mode === `port` ||
                  (item.sequence === 2 && this.state.mode === ``)
                    ? ``
                    : matches
                      ? classes.primaryBorder
                      : classes.allBorder
                }`}
                onClick={() => window.location.href = '/portability-plans'}
              >
                I want to Port
              </Button>
            </GridItem>
          </GridContainer>
          <GridContainer
            style={{
              justifyContent: `flex-start`
            }}
          >
            <GridItem
              xs={12}
              sm={12}
              md={6}
              lg={6}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              {this.state.mode !== `port` &&
                <Card
                  raised
                  className={`${classes.card} ${classes.cardStyle}`}
                  style={{
                    marginTop: `50px`
                  }}
                >
                  {(this.state.mode === `buy` ||
                    (item.sequence !== 2 && this.state.mode === ``)) && (
                    <CardBody
                      formHorizontal
                      style={{
                        paddingTop: `0px`,
                        paddingBottom: `0px`
                      }}
                    >
                      <form aria-label="" onSubmit={this.buy}>
                        <GridContainer>
                          <GridItem xs={12} sm={3} md={5}>
                            <CustomInput
                              labelText="Your Name"
                              id="name"
                              role="presentation"
                              formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                              }}
                              inputProps={{
                                "aria-label": `customName`,
                                name: `customName`
                              }}
                              value={this.state.form.name}
                              onChange={e => this.setInputValueToState(e, `name`)}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={5} md={4}>
                            <FormControl
                              fullWidth
                              className={`${classes.selectFormControl} ${classes.formMargin}`}
                              error={
                                this.state.submitStatus &&
                                this.state.form.insuranceType === ``
                              }
                            >
                              <InputLabel
                                htmlFor="insurancetype"
                                className={classes.selectLabel}
                              >
                                I want to Insure
                              </InputLabel>
                              <Select
                                MenuProps={{
                                  className: classes.selectMenu
                                }}
                                classes={{
                                  select: classes.select
                                }}
                                value={this.state.form.insuranceType || ``}
                                role="none"
                                onChange={this.handleSimple}
                                inputProps={{
                                  name: `insurancetype`,
                                  id: `insurancetype`
                                }}
                              >
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  value="myself"
                                >
                                  Myself
                                </MenuItem>
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  value="family"
                                >
                                  My Family
                                </MenuItem>
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  value="parents"
                                >
                                  My Parents
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </GridItem>
                          <GridItem
                            xs={12}
                            sm={4}
                            md={3}
                            style={{
                              alignSelf: `center`
                            }}
                          >
                            <Link
                              to={
                                this.state.links[this.state.form.insuranceType] && !sevaApp()
                                  ? this.state.links[
                                    this.state.form.insuranceType
                                  ] +
                                    `?name=${this.state.form.name}&noOfAdults=${
                                      this.state.adults[
                                        this.state.form.insuranceType
                                      ]
                                    }&noOfChildren=${
                                      this.state.children[
                                        this.state.form.insuranceType
                                      ]
                                    }`
                                  : sevaApp()?this.state.links[
                                    this.state.form.insuranceType
                                  ] +
                                    `?name=${this.state.form.name}&noOfAdults=${
                                      this.state.adults[
                                        this.state.form.insuranceType
                                      ]
                                    }&noOfChildren=${
                                      this.state.children[
                                        this.state.form.insuranceType
                                      ]
                                    }&sevapp=true` :`/`
                              }
                            >
                              <Button
                                block
                                color="primary"
                                value="buy now"
                                aria-label="buy now"
                                onClick={this.handleBuyNow}
                                className={`${classes.button} formFillBuyNowGA`}
                              >
                               <span className={`formFillBuyNowGA`}> Buy Now</span>
                              </Button>
                            </Link>
                          </GridItem>
                        </GridContainer>
                      </form>
                    </CardBody>
                  )}
                  {(this.state.mode === `renew` ||
                    (item.sequence === 2 && this.state.mode === ``)) && (
                    <CardBody
                      formHorizontal
                      style={{
                        paddingTop: `0px`,
                        paddingBottom: `0px`
                      }}
                    >
                      <form value="form" onSubmit={this.renewPolicy}>
                        <GridContainer>
                          <GridItem xs={12} sm={4} md={5}>
                            <CustomInput
                              id="name"
                              labelText="Your Name"
                              formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                              }}
                              value={this.state.form.name}
                              onChange={e => this.setInputValueToState(e, `name`)}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={4} md={4}>
                            <CustomInput
                              id="email"
                              labelText="Policy Number"
                              formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                              }}
                              value={this.state.form.policy}
                              onChange={e =>
                                this.setInputValueToState(e, `policy`)
                              }
                            />
                          </GridItem>
                          <GridItem
                            xs={12}
                            sm={4}
                            md={3}
                            style={{
                              alignSelf: `center`
                            }}
                          >
                            <Button
                              block
                              color="primary"
                              className={classes.button}
                              href={sevaApp()?`${CUSTOMERPORTAL_URL}/customerportal/instant-renewal?policyNumber=`+this.state.form.policy+'&sevaapp=true':`${CUSTOMERPORTAL_URL}/customerportal/instant-renewal?policyNumber=`+this.state.form.policy}
                              value=" "
                              aria-label=" "
                              target="_self"
                            >
                              Renew Now
                            </Button>
                          </GridItem>
                        </GridContainer>
                      </form>
                    </CardBody>
                  )}
                </Card>
              }
            </GridItem>
          </GridContainer>
          <GridContainer
            alignItems="flex-start"
            justify="flex-start"
            direction="row"
          >
            <Button
              aria-hidden="true"
              justIcon
              simple
              color="primary"
              className={classes.socialIcon}
              style={{ padding: `7px` }}
            >
              <FacebookShareButton
                value="facebook"
                aria-label="facebook"
                rel="noreferrer"
                quote={`Star Health`}
                url={`https://starhealth.in`}
              >
                <ReactSVG
                  src={withPrefix(`/web_facebook.svg`)}
                  style={{ width: `100%`, height: `100%` }}
                />
              </FacebookShareButton>
            </Button>

            <Button
              aria-hidden="true"
              justIcon
              simple
              color="primary"
              className={classes.socialIcon}
              style={{ padding: `7px` }}
            >
              <TwitterShareButton
                aria-label="twitter"
                value="twitter"
                rel="noreferrer"
                title={`Star Health`}
                url={`https://starhealth.i`}
              >
                <ReactSVG
                  src={withPrefix(`/web_twitter.svg`)}
                  style={{ width: `100%`, height: `100%` }}
                />
              </TwitterShareButton>
            </Button>
            <Button
              aria-hidden="true"
              justIcon
              simple
              color="white"
              className={classes.socialIcon}
              style={{ padding: `7px` }}
            >
              <WhatsappShareButton
                aria-label="whatsapp"
                value="whatsapp"
                rel="noreferrer"
                title={`Star Health`}
                url={`https://starhealth.in`}
              >
                <ReactSVG
                  src={withPrefix(`/web_whatsapp.svg`)}
                  style={{ width: `100%`, height: `100%` }}
                />
              </WhatsappShareButton>
            </Button>
          </GridContainer>
        </>
      ):(
        item.id !== 1002 &&
        <GridContainer
            style={{
              justifyContent: `left`,
              marginTop: matches ? `0px` : `25px`
            }}
          >
            <GridItem
              xs={12}
              sm={3}
              md={3}
              lg={2}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter,
                classes.buttonPadding
              )}
            >
              <Button
                block
                color="transparent"
                className={classNames(classes.button,classes.allBorder,classes.knowMoreButton)}
                onClick={() => {
                  root.open(`/corona-kavach-policy`, `_self`);
                }}
              >
                Know More
              </Button>
            </GridItem>
          </GridContainer> 
      )}
    </div>
  );

  testWebP = () => {
    var webP = new Image();
    webP.onload = webP.onerror = () => {
      this.setState({ webPSupport: webP.height == 2 ? true : false });
    };
    webP.src = `data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA`;
  };

  componentDidMount() {
    this.testWebP();
  }

  render() {
    const { classes, data } = this.props;
    const BannerImages = data.allStrapiImages.edges;
    const {
      sections,
      entities,
      keyfeatures
    } = data.allStrapiOrganizations.edges[0].node;
    const links = data.allStrapiLinks.edges;
    const features = links.map(link => {
      return link.node;
    });
    const promotionArr = entities
      .filter(entity => {
        return entity.category === `promotions` && entity.searchKey === `enable`;
      })
      .sort((a, b) => a.sequence - b.sequence);
    var trending = promotionArr.map(item => {
      const entityArr = data.allStrapiEntities.edges;
      for (let index = 0; index < entityArr.length; index++) {
        const element = entityArr[index];
        if (item.title === element.node.title) {
          item.icon = element.node.icon;
        }
      }
      return item;
    });
    let appData = [];

    for (let trendingIndex in trending) {
      if (trending[trendingIndex].url == `customer-banner`) {
        appData = BannerImages.filter(image => image.node)
          .filter(image => image.node.entity)
          .filter(image => image.node.entity.category == `promotions`)
          .map(image => image.node);
      }
    }

    for (let entity in trending) {
      if (trending[entity].url == `customer-banner`) {
        trending[entity].productLink = {};
        for (let app in appData) {
          trending[entity].productLink[appData[app].alt] = {
            imageUrl: appData[app].content.publicURL,
            link: appData[app].url,
            alt: appData[app].alt
          };
        }
        break;
      }
    }

    // trending.unshift({
    //   id: 768,
    //   title: `Customer app banner`,
    //   description: null,
    //   summary: null,
    //   category: `promotions`,
    //   url: `customerApp`,
    //   ctaLabel: null,
    //   productLink: {
    //     playstore: {
    //       link: `https://www.google.co.in/`,
    //       imageUrl: `https://lh3.googleusercontent.com/cjsqrWQKJQp9RFO7-hJ9AfpKzbUb_Y84vXfjlP0iRHBvladwAfXih984olktDhPnFqyZ0nu9A5jvFwOEQPXzv7hr3ce3QVsLN8kQ2Ao=s0`
    //     },
    //     appStore: {
    //       link: `https://www.wikipedia.org/`,
    //       imageUrl: `https://developer.apple.com/app-store/marketing/guidelines/images/badge-example-preferred.png`
    //     }
    //   },
    //   sequence: 1,
    //   icon: {
    //     publicURL: `https://uat.starhealth.in/sites/default/files/customerapp_temp.png`
    //   }
    // });
    const hassleFreeClaims = keyfeatures.map((item, index) => {
      var title = item.title;
      var description = item.description;
      let iconUrl;
      data.allStrapiKeyfeatures.edges.map(i => {
        if (i.node.title === title) iconUrl = i.node.icon.publicURL;
      });
      // var cards = item.questions.map(question => {
      //   return {
      //     id: question.id,
      //     title: question.title,
      //     summary: question.answers[0].summary
      //   };
      // });
      return {
        id: index,
        title: title,
        description: description,
        icon: iconUrl
      };
    });
    const awards = entities
      .filter(entity => {
        return entity.category === `awards`;
      })
      .map(item => {
        const entityArr = data.allStrapiEntities.edges.filter(
          entity =>
            entity.node.category === `awards` &&
            entity.node.organization != null
        );
        for (let index = 0; index < entityArr.length; index++) {
          const element = entityArr[index].node;
          if (element.title === item.title) {
            item.image = element.Image && element.Image.publicURL && element.Image.publicURL;
          }
        }
        return item;
      })
      .sort((a, b) => b.id - a.id)
      .slice(0, 4);
    const heroSection = sections.filter(section => {
      return section.category === `main` && section.page === `landing`;
    });

    const sortedHeroSection = heroSection.sort(function(a, b) {
      return a.sequence - b.sequence;
    });
    const landingPageSections = sections
    .filter(section => {
      return section.page === `landing` && section.type === `landingSection`;
    })
    .sort((a, b) => a.sequence - b.sequence)

    // const landingPageSections = allLandingPageSections.splice(
    //   1,
    //   allLandingPageSections.length - 1
    // );
    const trendingAdditionalDetails = {
      button: {
        text: `Know More`,
        color: `primary`
      }
    };

    const testimonials = data.allStrapiInsuranceplans.edges[0].node.entities.filter(
      res => {
        return res.category === `testimonials`;
      }
    );
    return (
      <Layout noParallax={true}>
        <Hidden mdDown >
        <Carousel {...this.settings}>
          {sortedHeroSection.map((item, index) => {
            let bannerContentItemId = (item.id !== 284 && item.id == 1002) ? true : false;
            let smImage = ``,
              clrImage = ``;
            for (let img of BannerImages) {
              if (img.node.section.sequence === item.sequence) {
                if (img.node.sequence === 1) {
                  smImage = this.state.webPSupport
                    ? img.node.webp && img.node.webp.childImageSharp.fluid
                    : img.node.content &&
                      img.node.content.childImageSharp.fluid;
                } else if (img.node.sequence === 2) {
                  clrImage = this.state.webPSupport
                    ? img.node.webp && img.node.webp.publicURL
                    : img.node.content && img.node.content.publicURL;
                }
              }
              if (smImage != `` && clrImage != ``) {
                break;
              }
            }
            let bgImage =
              browserWindow.navigator.userAgent.indexOf(`Edge`) !== -1
                ? `url(${clrImage})`
                : this.state[`banner-` + index]
                  ? `url(${clrImage})`
                  : `linear-gradient(black, black),url(${clrImage})`;

            return (
              <div key={index}>
                <MediaQuery maxWidth={767}>
                  {matches => {
                    return (
                      <>
                        {matches && (
                          <span>
                            <Img fluid={smImage} />
                          </span>
                        )}
                        <Hidden implementation="css" mdDown>
                        <div
                          className={classes.pageHeader}
                          style={{
                            backgroundImage: bgImage,
                            backgroundBlendMode: `saturation`,
                            marginTop: `0%`
                          }}
                          onMouseEnter={
                            matches
                              ? null
                              : () => this.handleMouseOver(`banner-` + index)
                          }
                          onMouseLeave={
                            matches
                              ? null
                              : () => this.handleMouseLeave(`banner-` + index)
                          }
                        >
                          <div>
                            {this.bannerContent(item, matches, classes, bannerContentItemId, 'mdDown')}
                          </div>
                        </div>
                        </Hidden>
                        <Hidden implementation="css" lgUp>
                        <div
                          className={classes.pageHeader}
                          style={{
                            backgroundBlendMode: `saturation`,
                            backgroundColor: `white`,
                            marginTop: `0%`
                          }}
                          onMouseEnter={
                            matches
                              ? null
                              : () => this.handleMouseOver(`banner-` + index)
                          }
                          onMouseLeave={
                            matches
                              ? null
                              : () => this.handleMouseLeave(`banner-` + index)
                          }
                        >
                          <div>
                            {this.bannerContent(item, matches, classes,bannerContentItemId, 'lgUp')}
                          </div>
                        </div>
                        </Hidden>
                      </>
                    );
                  }}
                </MediaQuery>
              </div>
            );
          })}
        </Carousel>
        </Hidden>
        {landingPageSections.map(landingPageSection => {
          let cards, additionalDetails;

          switch (landingPageSection.id) {
            case 3:
              cards = features;
              break;
            case 4:
              cards = trending;
              additionalDetails = trendingAdditionalDetails;
              break;
            case 6:
              cards = hassleFreeClaims;
              additionalDetails = {
                button: {
                  color: `primary`
                },
                ctaLabel: `Cashless Claims`,
                ctaUrl: `/claims#0#4`,
                ctaLabel1: `Reimbursement`,
                ctaUrl1: `/claims#0#5`,
                cardGroups: [
                  {
                    id: `cashlessClaim`,
                    title: `Cashless Claim`,
                    active: true
                  },
                  {
                    id: `reimbursement`,
                    title: `Reimbursement`
                  }
                ]
              };
              break;
            case 8:
              cards = testimonials;
              break;
            case 9:
              cards = awards;
              additionalDetails = {
                button: {
                  color: `primary`
                },
                ctaLabel: `Know More`,
                ctaUrl: `/media-center#1`
              };
              break;
          }

          return (
            <div
              className={`${classes.main} ${
                landingPageSection.id === 3
                  ? `0`
                  : landingPageSection.id % 3 === 0 &&
                    landingPageSection.id !== 8
                    ? classes.rightSectionsBackground
                    : landingPageSection.id === 9
                      ? classes.rightSectionsBackground
                      : classes.leftSectionsBackground
              }`}
              style={{
                backgroundImage: `${
                  landingPageSection.id === 3
                    ? `url(${withPrefix(`/pattern.svg`)})`
                    : landingPageSection.id % 3 === 0 &&
                      landingPageSection.id !== 8
                      ? `url(${withPrefix(`/star.svg`)})`
                      : landingPageSection.id === 9
                        ? `url(${withPrefix(`/star.svg`)})`
                        : `url(${withPrefix(`/star_inverted.png`)})`
                }`,
                backgroundColor: `${
                  landingPageSection.id % 3 === 0 && landingPageSection.id !== 8
                    ? `#f5f5f5`
                    : landingPageSection.id === 9
                      ? `#f5f5f5`
                      : ``
                }`,
                backgroundRepeat: `${
                  landingPageSection.id !== 3 ? `no-repeat` : ``
                }`,
                backgroundSize: `${landingPageSection.id === 3 ? `` : `300px`}`
              }}
              key={landingPageSection.id}
            >
              <div
                className={classes.container}
                style={{
                  maxWidth: `${
                    landingPageSection.id === 7 ? `1800px` : `1440px`
                  }`
                }}
              >
                {lSections(
                  landingPageSection.id,
                  cards,
                  landingPageSection.title,
                  landingPageSection.summary ? landingPageSection.summary.replace(stringSearchRegExp, STRHOSPITALCOUNT) : landingPageSection.summary,
                  additionalDetails,
                  data.allStrapiSections.edges
                )}
              </div>
            </div>
          );
        })}
        <PortabilityForm handleClose={(modal) => this.handleClose(modal)} signupModal={this.state.signupModal} />
        {process.env.ENABLE_HELMET == `true` && (
            <Helmet>
                <script type="application/ld+json">
                    {`{
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": [
                {
                  "@type": "Question",
                  "name": "What is the right age to get health insurance?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "A person can buy individual health insurance as soon as they turn 18 years of age. Buying a policy at an early age gives financial protection, and you can get much higher coverage at a lower premium."
                  }
                },
                {
                  "@type": "Question",
                  "name": "What is the best family insurance?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Numerous health insurance plans are available for families with a good range of coverage. A floater policy with wide range of sum insured options is the best for covering the entire family. Star Health offers various floater plans"
                  }
                },
                {
                  "@type": "Question",
                  "name": "What does health insurance cover?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Medical costs incurred as a result of disease/illness/accident are covered by health insurance. These medical costs include the cost of hospitalisation, pre and post hospitalisation, medications, implants, specialist fees and the cost of surgery, etc."
                  }
                },
                {
                  "@type": "Question",
                  "name": "When can we claim health insurance?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "In general, you can claim health insurance after a period of 30 days from the commencement of the health policy except for the claims that have waiting periods. However, in case of an accident, this initial waiting period is not applicable, and the cover is immediately provided if it is not for a pre-existing condition."
                  }
                },
                {
                  "@type": "Question",
                  "name": "How do I claim the health insurance amount?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "You can make your health insurance claim in one of the two ways: Cashless or Reimbursement Claims. However, the processes for these two kinds of health insurance claims vary."
                  }
                },
                {
                  "@type": "Question",
                  "name": "Is there a time limit to claim health insurance?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "If a policyholder receives an emergency treatment in a network hospital, the insurance company will directly settle the bill to the network hospital. You can claim reimbursement from your insurer if you are getting treated in a non-network hospitals.For reimbursement of claims, the intimation should be given within 24 hours of hospitalisation (in case of emergency hospitalisation). Claim must be filed within 15 days from the date of Discharge from the Hospital.In case of planned hospitalisation, you must notify your insurer at least 2 to 4 days before the scheduled hospitalisation."
                  }
                },
                {
                  "@type": "Question",
                  "name": "How can I get travel insurance?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "You can purchase travel insurance before or after booking your trip. However,one can get travel insurance from the official website or through an insurance agent."
                  }
                },
                {
                  "@type": "Question",
                  "name": "What is covered in international travel insurance?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Overseas Mediclaim policies cover the following sections:Medical Section - Emergency medical expenses, Dental emergency due to accident, Transporation of Mortal remains and emergency medical transportation.Accident Section – Personal Accident Travel Section - Loss of Baggage or Passport, Delay in Checked in baggage, Flight Delay, Missed departure of connection flight, Trip cancellation or interruption due to death of family member or a Hijack, etc.Liability Section - Personal Liability"
                  }
                },
                {
                  "@type": "Question",
                  "name": "Does everyone need travel insurance?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "If you have plans to travel overseas, you need to understand how important purchasing a travel insurance policy is. Your insurance company can cover most of your expenses that may arise from travel inconveniences."
                  }
                },
                {
                  "@type": "Question",
                  "name": "What is multi-trip travel insurance?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "The multi-trip travel insurance plan offers coverage for a predetermined period of time, usually for a year and allows you to take as many trips as you'd like while still being covered under the same policy. Star Health offers Corporate travel plan with this kind of benefit."
                  }
                },
                {
                  "@type": "Question",
                  "name": "Who can take personal accident insurance?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "The Personal Accident Policy can be taken by any individual between the age of 18 and 70 years living in India. Some policies also cover dependent children from the age of 5 months to 25 years."
                  }
                },
                {
                  "@type": "Question",
                  "name": "What is covered in personal accident insurance?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "The policy covers accidental death, permanent  total disablement, permanent partial disablement and temporary total disablement due to accident."
                  }
                },
                {
                  "@type": "Question",
                  "name": "What is group accident policy?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Group accident insurance is a sort of insurance that is provided to employees by the employer to guard them against the medical costs associated with accidents and related injuries."
                  }
                },
                {
                  "@type": "Question",
                  "name": "How does insurance work in case of an accident?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "If a policyholder met with an accident, the insurer will provide necessary financial support to cover the treatment expenses. In addition, it also provides cover for death and disablement due to accidents. Some policies also provide educational grant to the insured’s dependent children studying in school or college."
                  }
                },
                {
                  "@type": "Question",
                  "name": "Does accident insurance cover sports injuries?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Most health insurance companies do not cover  hazardous sports-related injuries for various reasons."
                  }
                }
              ]
            }`}</script>
            <script type="application/ld+json">
              {`{
              "@context": "https://schema.org",
              "@type": "Table",
              "about": "Key benefits of being with Star Health Insurance"
            }`}
            </script>
            </Helmet>
            )}
      </Layout>
    );
  }
}
IndexPage.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
};
export default withStyles(landingPageStyle)(IndexPage);

export const query = graphql`
  query HomePageQuery {
    allStrapiOrganizations {
      edges {
        node {
          id
          keyfeatures {
            id
            title
            description
          }
          entities {
            id
            title
            description
            summary
            category
            url
            ctaLabel
            sequence
            searchKey
          }
          sections {
            id
            title
            summary
            ctaLabels
            category
            page
            sequence
            type
          }
          links {
            id
            category
            title
            url
            organization
          }
          insuranceplancategories {
            id
            name
            summary
            title
            colorCode
            ctaLabel
            url
            category
            segment
            organization
          }
        }
      }
      pageInfo {
        hasNextPage
      }
    }
    allStrapiInsuranceplans {
      edges {
        node {
          entities {
            id
            title
            description
            summary
            category
            url
          }
          testimonialHeading
          testimonialDescription
        }
      }
    }
    allStrapiEntities(filter: { category: { in: ["awards", "promotions"] } }) {
      edges {
        node {
          title
          category
          sequence
          icon {
            publicURL
          }
          Image {
            publicURL
          }
          organization {
            id
          }
        }
      }
    }
    allStrapiLinks(
      filter: { category: { eq: "tasks" } }
      sort: { fields: sequence, order: ASC }
    ) {
      edges {
        node {
          id
          sequence
          category
          sub_category
          title
          url
          icon {
            publicURL
          }
        }
      }
    }
    allStrapiImages(
      filter: { section: { category: { in: ["promotions", "main"] } } }
    ) {
      edges {
        node {
          id
          alt
          sequence
          url
          entity {
            id
            category
          }
          content {
            publicURL
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          webp {
            publicURL
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          section {
            sequence
          }
        }
      }
    }
    allStrapiSections(filter: { page: { eq: "landing" } , category: { in: ["health","travel","accident"] } }) {
      edges {
          node {
              title,
              description,
              summary,
              category,
              type,
              image {
                  publicURL
                  childImageSharp {
                    fluid(quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
              }
          }
      }
    }
    allStrapiKeyfeatures {
      edges {
        node {
          title
          icon {
            publicURL
          }
        }
      }
    }
  }
`;
