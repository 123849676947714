import React from "react";
// react component for creating beautiful carousel
import Carousel from "react-slick";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import PropTypes from "prop-types";
import FormatQuote from "@material-ui/icons/FormatQuote";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from "components/CustomButtons/Button.jsx";

import testimonialsStyle from "ps-assets/jss/testimonialsStyle.jsx";
import { Link } from "gatsby";

function SectionTestimonials({ ...props }) {
  const { classes, cards, title, description, hideReadMore,slidesToScroll,slidesToShow,carouselHeight, carouselStye,cardContent,...rest } = props;
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToScroll,
    autoplay: false,
    initialSlide: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: true
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
          // initialSlide: 0
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className="cd-section" style={carouselStye} {...rest}>
      <div className={cardContent ? classes.testimonials : ``}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={6}
              md={12}
              className={`${classes.mlAuto} ${classes.mrAuto} ${classes.textCenter}`}
            >
              {title && <h2 className={classes.title}>{title}</h2>}
              {description && <p className={classes.description} style={{fontSize:"17px"}}>{description}</p>}
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Carousel {...settings}>
                {cards &&
                  cards.map((card, idx) => {
                    return (
                      <div key={idx}>
                        <GridItem xs={12} sm={12} md={12} key={card.id}>
                          <Card
                            testimonial
                            className={classes.card1}
                            style={{
                              height: carouselHeight,
                              background: `transparent`
                            }}
                          >
                            {cardContent ? (
                              cardContent(card.summary)
                            ) : (
                              <>
                                <div className={classes.icon}>
                                  <FormatQuote color="primary" />
                                </div>
                                <CardBody>
                                  <div
                                    className={classes.cardDescription}
                                    style={{ marginBottom: 0 }}
                                  >
                                    <p>
                                    {card.summary}
                                    </p>
                                  </div>
                                </CardBody>
                              </>
                            )}
                            <CardFooter testimonial>
                              <p className={classes.cardTitle} style={{fontSize:"18px"}}>
                                {card.title}
                              </p>
                            </CardFooter>
                          </Card>
                        </GridItem>
                      </div>
                    );
                  })}
              </Carousel>
            </GridItem>
          </GridContainer>
          {!hideReadMore && (
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} className={classes.textCenter}>
                <Link to="/testimonials">
                  <Button round color="primary">
                    Read More
                  </Button>
                </Link>
              </GridItem>
            </GridContainer>
          )}
        </div>
      </div>
    </div>
  );
}

SectionTestimonials.defaultProps = {
  slidesToShow: 3,
  slidesToScroll: 3,
  carouselHeight : 300,
  carouselStye:{}
}

SectionTestimonials.propTypes = {
  classes: PropTypes.object,
  cards: PropTypes.array,
  title: PropTypes.string,
  description: PropTypes.string,
  hideReadMore: PropTypes.bool,
  slidesToShow: PropTypes.number,
  slidesToScroll: PropTypes.number,
  carouselHeight:PropTypes.number,
  carouselStye:PropTypes.object,
  cardContent: PropTypes.object
};
export default withStyles(testimonialsStyle)(SectionTestimonials);
