import React from 'react'
import classNames from "classnames";
import PropTypes from "prop-types";
import GridContainer from 'components/Grid/GridContainer';
import withStyles from "@material-ui/core/styles/withStyles";
import LandingPagePlansStyles from 'ps-assets/jss/LandingPagePlansStyles.jsx'
// import { graphql } from 'gatsby'
import { browserWindow } from "util/localStorageHelper";
import { sevaApp } from 'util/LayoutUtil.jsx';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Accordion from "components/Accordion/Accordion.jsx";

class LandingPagePlans extends React.Component {
    state ={
        buttonSelected: `health`,
    };
    render(){
      let AccordionArray = faq.map(category => {
        return {
          title: category.title,
          content: (
            <>
            {category.content ? category.description : <h5 style={{fontSize: '.875rem'}}>{category.description}</h5>}
            </>
          )
        };
      });
      let AccordionArraytravel = travelfaq.map(category => {
        return {
          title: category.title,
          content: (
            <>
            {category.content ? category.description : <h5 style={{fontSize: '.875rem'}}>{category.description}</h5>}
            </>
          )
        };
      });
      let AccordionArrayaccident = accidentfaq.map(category => {
        return {
          title: category.title,
          content: (
            <>
            {category.content ? category.description : <h5 style={{fontSize: '.875rem'}}>{category.description}</h5>}
            </>
          )
        };
      });
        const { classes, plansData } = this.props
        const planSelected = this.state.buttonSelected
        const selectedPlanData = plansData.filter(item => item.node.category === planSelected)[0]
        const handleNavButton = (e) => {
            const name = e.target.name
            this.setState({buttonSelected: name})
        }
        const handlePlanButton = (link) => {
          if(sevaApp()){
            browserWindow.location.href = link+'?sevaapp=true'
          }else{
            browserWindow.location.href = link
          }
            
        }
        return (
          <GridContainer className={classNames(classes.sectionBackground)}>
            <div className={classNames(classes.navList)}>
              <button
                name="health"
                onClick={handleNavButton}
                className={`${classes.navButton} ${
                  planSelected == `health` ? classes.navButtonActive : ""
                }`}
              >
                Health Insurance
              </button>
              <button
                name="travel"
                onClick={handleNavButton}
                className={classNames(
                  classes.navButton,
                  planSelected == `travel` ? classes.navButtonActive : ``
                )}
              >
                Travel Insurance
              </button>
              <button
                name="accident"
                onClick={handleNavButton}
                className={classNames(
                  classes.navButton,
                  planSelected == `accident` ? classes.navButtonActive : ``
                )}
              >
                Accident Insurance
              </button>
            </div>
            <div className={classes.planCard}>
              <section>
                <div className={classes.planSummary}>
                  {selectedPlanData.node.title==`Health Insurance` ?
                  <h1>{selectedPlanData.node.title}</h1>
                  :<h2>{selectedPlanData.node.title}</h2>
                  }
                  <p>{selectedPlanData.node.description}</p>
                </div>
                <div className={classes.planImage}>
                    <img src={images[selectedPlanData.node.category]} style={{ maxWidth: `100%`, maxHeight: `100%`, }}/>
                </div>
              </section>
              {list[selectedPlanData.node.category] &&
                <p>
                    <h2 style={{fontSize: '1.5rem'}} className={classes.h2Title} >Key benefits of being with Star Health Insurance</h2>
                </p>
              }
              <section>{list[selectedPlanData.node.category]}</section>
                <button
                    className={classes.ctaButton}
                    onClick={() =>
                    handlePlanButton(links[selectedPlanData.node.category])
                    }
                >
                    View Plans
                </button>
                {selectedPlanData.node.title==`Health Insurance` ? <Accordion active={0} collapses={AccordionArray} /> : selectedPlanData.node.title==`International Travel Insurance` ? <Accordion active={0} collapses={AccordionArraytravel} /> : <Accordion active={0} collapses={AccordionArrayaccident} />}
            </div>
          </GridContainer>
        );
    }
}

LandingPagePlans.propTypes = {
    classes: PropTypes.object,
    plansData: PropTypes.object,
  };

export default withStyles(LandingPagePlansStyles)(LandingPagePlans);

var list = {
    accident: 
    <Table style={{width: '77%'}}>
      <TableHead>
      <TableRow style={{height: '30px'}}>
          <TableCell style={{border: `1px solid black`,fontWeight: "bold",color: "black",}}>Key Feature</TableCell>
          <TableCell style={{border: `1px solid black`,fontWeight: "bold",color: "black",}}>Benefits</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
      <TableRow style={{height: '30px'}}>
          <TableCell style={{border: `1px solid black`}}>Death - 100% Sum</TableCell>
          <TableCell style={{border: `1px solid black`}}>insured payable</TableCell>
        </TableRow>
        <TableRow style={{height: '30px'}}>
          <TableCell style={{border: `1px solid black`}}>Permanent Total Disability</TableCell>
          <TableCell style={{border: `1px solid black`}}>150 % of Sum-insured payable</TableCell>
        </TableRow>
        <TableRow style={{height: '30px'}}>
          <TableCell style={{border: `1px solid black`}}>Permanent Partial Disability</TableCell>
          <TableCell style={{border: `1px solid black`}}>Percentage of Sum-insured Payable</TableCell>
        </TableRow>
        <TableRow style={{height: '30px'}}>
          <TableCell style={{border: `1px solid black`}}>Temporary Total Disablement (weekly compensation)</TableCell>
          <TableCell style={{border: `1px solid black`}}>up to 100 Weeks Payable</TableCell>
        </TableRow>
      </TableBody>
    </Table>,
    // <ol>
    //     <li><b>Death - 100% Sum</b> -insured payable</li>
    //     <li><b>Permanent Total Disability</b> :- 150 % of Sum-insured payable</li>
    //     <li><b>Permanent Partial Disability</b> :- Percentage of Sum-insured Payable</li>
    //     <li><b>Temporary Total Disablement (weekly compensation)</b> :-  up to 100 Weeks Payable</li>
    // </ol>,
    health:
    <Table style={{width: '67%'}}>
      <TableHead>
        <TableRow style={{height: '30px'}}>
          <TableCell style={{border: `1px solid black`,fontWeight: "bold",color: "black",}}>Key Feature</TableCell>
          <TableCell style={{border: `1px solid black`,fontWeight: "bold",color: "black",}}>Benefits</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow style={{height: '30px'}}>
          <TableCell style={{border: `1px solid black`}}>Protection for</TableCell>
          <TableCell style={{border: `1px solid black`}}>Individual / Families on Floater Basis</TableCell>
        </TableRow>
        <TableRow style={{height: '30px'}}>
          <TableCell style={{border: `1px solid black`}}>Sum Insured (INR) </TableCell>
          <TableCell style={{border: `1px solid black`}}>Up to 2 crores</TableCell>
        </TableRow>
        <TableRow style={{height: '30px'}}>
          <TableCell style={{border: `1px solid black`}}>Innovative Products</TableCell>
          <TableCell style={{border: `1px solid black`}}>Customer-Centric Policies</TableCell>
        </TableRow>
        <TableRow style={{height: '30px'}}>
          <TableCell style={{border: `1px solid black`}}>Hassle-Free Claims</TableCell>
          <TableCell style={{border: `1px solid black`}}>89.9% in less than 2 hours</TableCell>
        </TableRow>
        <TableRow style={{height: '30px'}}>
          <TableCell style={{border: `1px solid black`}}>Cashless Facility</TableCell>
          <TableCell style={{border: `1px solid black`}}>14000+ Network Hospitals</TableCell>
        </TableRow>
        <TableRow style={{height: '30px'}}>
          <TableCell style={{border: `1px solid black`}}>In-house Claim Settlement</TableCell>
          <TableCell style={{border: `1px solid black`}}>On all 365 days by Qualified Doctors</TableCell>
        </TableRow>
        <TableRow style={{height: '30px'}}>
          <TableCell style={{border: `1px solid black`}}>Digital Platform</TableCell>
          <TableCell style={{border: `1px solid black`}}>Highly sophisticated website</TableCell>
        </TableRow>
        <TableRow style={{height: '30px'}}>
          <TableCell style={{border: `1px solid black`}}>Pre-insurance Medical Screening</TableCell>
          <TableCell style={{border: `1px solid black`}}>Not Mandatory in most of our Policies</TableCell>
        </TableRow>
      </TableBody>
    </Table>,
    // <ol>
    //     <li><b>Protection for</b> - Individual / Families on Floater Basis</li>
    //     <li><b>Sum Insured (INR)</b> - up to 2 crores</li>
    //     <li><b>Innovative Products</b> - Customer-Centric Policies</li>
    //     <li><b>Hassle-Free Claims</b> - 89.9% in less than 2 hours</li>
    //     <li><b>Cashless Facility</b> - 13000+ Network Hospitals</li>
    //     <li><b>In-house Claim Settlement</b> - On all 365 days by Qualified Doctors</li>
    //     <li><b>Digital Platform</b> - Highly sophisticated website</li>
    //     <li><b>Pre-insurance Medical Screening</b> - Not Mandatory in most of our Policies</li>
    // </ol>,
}


var links = {
    accident: `/accident-plans`,
    health: `/health-insurance-plans`,
    travel: `/travel-insurance-plans`,
}

var images = {
    accident: "/Accident-insurance.png",
    health: "/Health-Insurance.jpg",
    travel: "/Travel-Insurance.png",
}

const faq=[
  {
      title:'What is the right age to get health insurance',
      description:'A person can buy individual health insurance as soon as they turn 18 years of age. Buying a policy at an early age gives financial protection, and you can get much higher coverage at a lower premium.',
      content:false
  },
  {
      title:'What is the best family insurance?',
      description:'Numerous health insurance plans are available for families with a good range of coverage. A floater policy with wide range of sum insured options is the best for covering the entire family. Star Health offers various floater plans.',
      content:false
  },
  {
      title:'What does health insurance cover?',
      description:'Medical costs incurred as a result of disease/illness/accident are covered by health insurance. These medical costs include the cost of hospitalisation, pre and post hospitalisation, medications, implants, specialist fees and the cost of surgery, etc.',
      content:false
  },
  {
      title:'When can we claim health insurance?',
      description:'In general, you can claim health insurance after a period of 30 days from the commencement of the health policy except for the claims that have waiting periods. However, in case of an accident, this initial waiting period is not applicable, and the cover is immediately provided if it is not for a pre-existing condition.',
      content:true
  },
  {
      title:'How do I claim the health insurance amount?',
      description:'You can make your health insurance claim in one of the two ways: Cashless or Reimbursement Claims. However, the processes for these two kinds of health insurance claims vary.',
      content:true
  },
  {
      title:'Is there a time limit to claim health insurance?',
      description:<h5><h5 style={{fontSize: '.875rem'}}>If a policyholder receives an emergency treatment in a network hospital, the insurance company will directly settle the bill to the network hospital. You can claim reimbursement from your insurer if you are getting treated in a non-network hospitals.</h5>
      <h5 style={{fontSize: '.875rem'}}>For reimbursement of claims, the intimation should be given within 24 hours of hospitalisation (in case of emergency hospitalisation). Claim must be filed within 15 days from the date of Discharge from the Hospital.</h5>
      <h5 style={{fontSize: '.875rem'}}>In case of planned hospitalisation, you must notify your insurer at least 2 to 4 days before the scheduled hospitalisation</h5></h5>,
      content:false
  },
]

const travelfaq=[
  {
      title:'How can I get travel insurance?',
      description:'You can purchase travel insurance before or after booking your trip. However,one can get travel insurance from the official website or through an insurance agent.',
      content:false
  },
  {
      title:'What is covered in international travel insurance?',
      description:<h5><h5 style={{fontSize: '.875rem'}}><b>Overseas Mediclaim policies cover the following sections:</b></h5>
      <h5 style={{fontSize: '.875rem'}}><b>Medical Section</b> - Emergency medical expenses, Dental emergency due to accident, Transporation of Mortal remains and emergency medical transportation</h5>
      <h5 style={{fontSize: '.875rem'}}><b>Accident Section</b> - Personal Accident</h5>
      <h5 style={{fontSize: '.875rem'}}><b>Travel Section</b> - Loss of Baggage or Passport, Delay in Checked in baggage, Flight Delay, Missed departure of connection flight, Trip cancellation or interruption due to death of family member or a Hijack, etc.</h5>
      <h5 style={{fontSize: '.875rem'}}><b>Liability Section</b> - Personal Liability</h5></h5>,
      content:false
  },
  {
      title:'Does everyone need travel insurance?',
      description:'If you have plans to travel overseas, you need to understand how important purchasing a travel insurance policy is. Your insurance company can cover most of your expenses that may arise from travel inconveniences.',
      content:false
  },
  {
      title:'What is multi-trip travel insurance?',
      description:'The multi-trip travel insurance plan offers coverage for a predetermined period of time, usually for a year and allows you to take as many trips as you’d like while still being covered under the same policy. Star Health offers Corporate travel plan with this kind of benefit.',
      content:true
  },
]

const accidentfaq=[
  {
      title:'Who can take personal accident insurance?',
      description:'The Personal Accident Policy can be taken by any individual between the age of 18 and 70 years living in India. Some policies also cover dependent children from the age of 5 months to 25 years.',
      content:false
  },
  {
      title:'What is covered in personal accident insurance?',
      description:'The policy covers accidental death, permanent  total disablement, permanent partial disablement and temporary total disablement due to accident.',
      content:false
  },
  {
      title:'What is group accident policy?',
      description:'Group accident insurance is a sort of insurance that is provided to employees by the employer to guard them against the medical costs associated with accidents and related injuries.',
      content:false
  },
  {
      title:'How does insurance work in case of an accident?',
      description:'If a policyholder met with an accident, the insurer will provide necessary financial support to cover the treatment expenses. In addition, it also provides cover for death and disablement due to accidents. Some policies also provide educational grant to the insured’s dependent children studying in school or college.',
      content:true
  },
  {
    title:'Does accident insurance cover sports injuries?',
    description:'Most health insurance companies do not cover hazardous sports-related injuries for various reasons.',
    content:true
},
]